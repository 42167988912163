/* Room.css */
.room-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    background-color: #18181b;
    color: #f4f4f5;
    overflow: hidden; /* Prevent page scrolling */
}

.room-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    border-bottom: 1px solid #26262c;
}

.header-left, .header-right {
    display: flex;
    align-items: center;
}

.logo-room {
    height: 25px;
    margin-right: 10px;
}

.sidebar-toggle-button {
    background: none;
    color: #f4f4f5;
    border: none;
    font-size: 20px;
    margin-right: 10px;
    cursor: pointer;
}

.project-title-dropdown {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.project-title {
    font-size: 16px;
    font-weight: 500;
    margin-right: 5px;
}

.dropdown-icon {
    font-size: 10px;
    color: #9ca3af;
    margin-bottom: 2px;
}

.header-icon-button,
.header-supabase-button,
.header-edit-code,
.header-publish {
    background-color: #26262c;
    color: #f4f4f5;
    border: none;
    padding: 8px 12px;
    border-radius: 6px;
    margin-left: 10px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
}

.header-supabase-button {
    background-color: #3b82f6;
}
.header-supabase-button svg {
    fill: white;
    height: 16px;
    width: 16px;
}
.header-publish {
    background-color: #059669;
}

.room-body {
    display: flex;
    height: calc(100% - 60px - 1px); /* Header height + border */
}

.room-sidebar {
    width: 380px;
    background-color: #121214;
    border-right: 1px solid #26262c;
    display: flex;
    flex-direction: column;
    transition: width 0.3s ease-in-out;
}

.room-sidebar.closed {
    width: 60px; /* Adjust as needed for closed sidebar width */
    overflow: hidden;
}
.room-sidebar.closed .sidebar-tabs,
.room-sidebar.closed .sidebar-content {
    display: none;
}
.room-sidebar.closed .sidebar-toggle-button {
    margin-left: 15px; /* Adjust position when closed */
}


.sidebar-tabs {
    display: flex;
    border-bottom: 1px solid #26262c;
}

.sidebar-tab {
    background: none;
    color: #9ca3af;
    border: none;
    padding: 12px 20px;
    font-size: 15px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    overflow: hidden; /* Added overflow hidden */
    text-overflow: ellipsis; /* Added text overflow ellipsis */
    white-space: nowrap; /* Prevent text wrapping */
}

.sidebar-tab.active {
    color: #f4f4f5;
    border-bottom-color: orange; /* Orange border for active tab */
    border-bottom-width: 3px;
}

.sidebar-content {
    padding: 20px;
    overflow-y: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.chat-tab-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}


.chat-messages-container {
    flex-grow: 1;
    overflow-y: auto;
    padding-bottom: 10px;
    display: flex; /* Enable flexbox for chat messages container */
    flex-direction: column; /* Stack messages vertically */
}

/* Skinny Scrollbar for chat messages container */
.chat-messages-container::-webkit-scrollbar {
    width: 5px; /* Thin scrollbar */
}

.chat-messages-container::-webkit-scrollbar-track {
    background: #1e1e21; /* Match background */
}

.chat-messages-container::-webkit-scrollbar-thumb {
    background-color: #6b7280; /* Gray color for thumb */
    border-radius: 5px;
}


.chat-message {
    background-color: #26262c;
    border-radius: 8px;
    padding: 10px 12px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    width: fit-content; /* Adjust width to content */
    max-width: 70%; /* Limit message width */
    align-self: flex-start; /* Default to left for Bot */
    overflow: hidden; /* Added overflow hidden for chat bubbles */
    word-wrap: break-word; /* Added word wrap for chat bubbles */
}

.chat-message.user {
    background-color: #1e293b;
    align-self: flex-end; /* Align user messages to the right */
}

.message-sender {
    font-size: 12px;
    color: #9ca3af;
    margin-bottom: 3px;
}

.message-text {
    font-size: 14px;
    word-wrap: break-word;
}
.message-time {
    font-size: 11px;
    color: #9ca3af;
    align-self: flex-end;
    margin-top: 3px;
}


.ask-lovable-input {
    display: flex;
    align-items: flex-start; /* Align items to top to position buttons below input */
    padding-top: 15px;
    border-top: 1px solid #26262c;
}

.input-buttons {
    display: flex;
    gap: 8px;
    margin-right: 10px;
}
.input-buttons-bottom {
    display: flex;
    gap: 8px;
    margin-top: 5px; /* Spacing between input and buttons */
}


.attach-button,
.select-button {
    background-color: #26262c;
    color: #f4f4f5;
    border: none;
    padding: 8px 10px;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.attach-button svg {
    font-size: 16px;
}


.input-area {
    flex-grow: 1;
    display: flex;
    align-items: center;
    background-color: #26262c;
    border-radius: 6px;
    padding: 0 10px;
    height: 40px; /* Increased height */
    max-height: 150px; /* Max height for input area */
    overflow-y: auto; /* Enable vertical scroll if content exceeds max-height */
    overflow-x: hidden; /* Added overflow x hidden */
    word-wrap: break-word; /* Added word wrap for input area */
}
.input-area::-webkit-scrollbar {
    width: 5px; /* Thin scrollbar for input area if it scrolls */
}

.input-area::-webkit-scrollbar-track {
    background: #1e1e21; /* Match background */
}

.input-area::-webkit-scrollbar-thumb {
    background-color: #6b7280; /* Gray color for thumb */
    border-radius: 5px;
}


.input-area input {
    background: none;
    border: none;
    color: #f4f4f5;
    padding: 8px 0;
    font-size: 14px;
    flex-grow: 1;
    height: 100%; /* Make input take full height of input-area */
}
.input-area input:focus {
    outline: none;
}


.send-button {
    background: none;
    color: #f4f4f5;
    border: none;
    font-size: 20px;
    cursor: pointer;
    padding: 5px;
    align-self: flex-end; /* Align send button to the end of ask-lovable-input */
    margin-left: 10px; /* Add some spacing */
    margin-bottom: 5px; /* Align with the bottom buttons */
}


.room-content {
    flex-grow: 1;
    background-color: #09090b;
    position: relative; /* For absolute positioning of error box */
}

.error-box {
    background-color: #1e1e21;
    border-radius: 8px;
    padding: 15px 20px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 300px;
}

.error-box-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 8px;
}
.error-box-header .error-icon {
    font-size: 16px;
}


.error-box-message {
    font-size: 14px;
    color: #d4d4d8;
    margin-bottom: 15px;
}

.error-box-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.try-to-fix-button,
.show-logs-button {
    background-color: #26262c;
    color: #f4f4f5;
    border: none;
    padding: 8px 12px;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
}


/* History Tab Content Styling */
.history-tab-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.history-card {
    background-color: #1e1e21;
    border-radius: 8px;
    padding: 15px;
}
.history-card-title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
}
.history-card-date {
    font-size: 12px;
    color: #9ca3af;
    margin-bottom: 8px;
}
.history-card-description {
    font-size: 14px;
    color: #d4d4d8;
    line-height: 1.5;
}