/* src/components/Explore.css */
.explore-container {
  display: flex;
  height: 98.5vh;
  overflow: hidden; /* Prevent scrollbars on the main container */
}

.main-content {
  flex-grow: 1;
  padding: 0;
  transition: margin-left 0.3s ease;
  overflow: hidden; /* Prevent scrollbars on the main content */
}

.map-container {
  width: 100%;
  height: 100%;
  border-radius: 15px; /* Rounded edges for the map */
  position: relative;
}

.map-overlay-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Allow interactions with the map below */
  z-index: 10; /* Ensure overlays are above the map */
}

.map-overlay {
  position: absolute;
}

/* Removed .top-left class */

.bottom-right { /* New bottom-right positioning for toolbox */
  bottom: 20px;
  right: 20px;
}

.top-center {
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.toolbox {
  display: flex;
  gap: 5px;
  background-color: rgba(240, 240, 240, 0.8); /* Add transparency */
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  pointer-events: auto; /* Allow interactions with the toolbox */
}

.toolbox .icon {
  margin: 0;
  padding: 5px;
  cursor: pointer;
}

/* Top Left Branding Container */
.top-left-branding {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  pointer-events: auto; /* Make clickable */
  z-index: 20; /* Ensure branding is above the map */
}

/* Back Arrow Style */
.back-arrow {
  cursor: pointer;
  margin-right: 10px;
  border: 2px solid grey;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(61, 61, 61, 0.6); /* Consistent grey background */
}

/* Brand Text Style */
.brand-text {
  color: #FF6700; /* Brand Orange */
  font-weight: bold;
  font-size: 1em; /* Adjust size as needed */
  border: 2px solid grey;
  padding: 5px 10px;
  border-radius: 20px;
  background-color: rgba(61, 61, 61, 0.6); /* Consistent grey background */
}

.search-bar {
  padding: 8px 15px;
  border: 3px solid #FF6700; /* Orange border */
  border-radius: 20px;
  width: 400px;
  background-color: rgba(0, 0, 0, 0.8); /* More black background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  color: #fff;
  outline: none;
  pointer-events: auto;
  height: auto; /* Adjust height automatically */
  overflow-y: auto; /* Enable vertical scroll if content exceeds max-height */
  resize: vertical; /* Allow vertical resizing if needed */
  min-height: 40px; /* Minimum height to avoid collapsing */
  line-height: 1.4em; /* Adjust line height for multi-line text */
  display: block; /* Ensure block display for height: auto to work correctly */
  max-height: 5em; /* Limit to ~4 lines (line-height * 4 + padding + border) */
  font-family: 'Arial', sans-serif; /* Inherit font-family */
  white-space: pre-wrap; /* Handle line breaks */
  word-wrap: break-word; /* Break long words */
}

.search-bar::placeholder {
  color: white; /* Lighter placeholder text */
}

.dropdown-icon {
  position: relative;
  display: inline-block;
  margin-right: 0px;
}

.icon-with-arrow {
  display: flex;
  align-items: center;
}

.dropdown-arrow {
  margin-left: 5px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  list-style: none;
  padding: 5px 0;
  margin: 0;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  transform: translateY(-5px);
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.dropdown-icon:hover .dropdown-menu {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

.dropdown-menu li {
  padding: 5px 15px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #eee;
}

.map-type-switcher {
  margin-left: auto;
}

.map-type-switcher .dropdown-menu {
  left: auto;
  right: 0;
}

.loading-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
}

.map-container-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Styles for Mapbox GL Draw (if needed, customize as you like) */
.mapboxgl-ctrl-group button {
  /* Style for all buttons in the control group */
  margin: 0; /* Remove default margin */
  background-color: white; /* Set default background color to black */
  border: none; /* Remove default border */
  cursor: pointer;
}

/* Style for the icon container */
.search-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

/* Style for the icon itself */
.search-icon-container i {
  font-size: 14px; /* Adjust icon size as needed */
  color: white; /* Set default icon color to white */
}

/* Style for the icon container */
.map-style-icon-container {
display: flex;
align-items: center;
justify-content: center;
width: 100%;
height: 100%;
}

/* Style for the icon itself */
.map-style-icon-container i {
font-size: 14px; /* Adjust icon size as needed */
color: white; /* Set default icon color to white */
}

.mapboxgl-ctrl-search {
  /* Specific style for the search button */
  background-color: white; /* Or your desired background color */
}

/* Styles for the map style button */
.mapboxgl-ctrl-map-style {
  background-color: white;
}

/* Styles for the drawing tools */
.mapbox-gl-draw-tool {
  background-color: white; /* Set background color to black */
}

.mapbox-gl-draw-tool i {
  color: white; /* Set icon color to white */
}

/* Map style dropdown container */
.map-style-dropdown-container {
position: absolute;
top: 45%;
left: 50%;
transform: translate(-50%, -50%);
background-color: black;
border: 1px solid black;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
z-index: 100;
border-radius: 10px;
overflow: hidden;

/* Set CSS variables for initial dimensions (can be adjusted) */
--dropdown-initial-width: 500px; /* Adjust as needed */
--dropdown-initial-height: 400px; /* Adjust as needed */

/* Increase size by 20% using calc() */
width: calc(var(--dropdown-initial-width) * 1.2);
height: calc(var(--dropdown-initial-height) * 1.2);
}
/* Close button for the dropdown */
.map-style-dropdown-close {
position: absolute;
top: 5px;
right: 5px;
padding: 5px;
background: none;
border: none;
cursor: pointer;
}

.close-icon-container {
display: flex;
align-items: center;
justify-content: center;
width: 100%;
height: 100%;
}

/* Title for the dropdown */
.map-style-dropdown-title {
color: white;
padding: 10px;
font-weight: bold;
border-bottom: 1px solid #ccc;
text-align: center;
margin-bottom: 10px; /* Add some space below the title */
}

/* Content inside the map style dropdown */
.map-style-dropdown-content {
padding: 10px;
}

.map-style-grid {
display: grid;
grid-template-columns: repeat(4, 1fr); /* 4 columns */
gap: 10px;
padding: 10px;
}

.map-style-item {
text-align: center;
cursor: pointer;
}

.map-style-image {
width: 120px; /* Adjust image size as needed */
height: 80px; /* Adjust image size as needed */
object-fit: cover;
border-radius: 8px; /* Rounded edges for images */
margin-bottom: 5px;
}

.map-style-name {
font-size: 1.0em;
color: white
}

.map-style-dropdown-content a {
display: block;
padding: 8px 12px;
text-decoration: none;
color: black;
}

.map-style-dropdown-content a:hover {
background-color: #eee;
}

/* Highlight the active map style */
.map-style-item.active {
background-color: #e0e0e0; /* Light gray background */
border: 2px solid orange; /* Orange border */
}

/* Container for the title */
.map-style-title-container {
padding: 10px;
text-align: center;
}

/* Container for the React-rendered content */
.map-style-content-container {
padding: 10px;
}

/* Mapbox GL Draw Toolbar */
.mapboxgl-ctrl-group.mapboxgl-ctrl {
  background-color: black; /* Set the background of the toolbar to black */
}

/* Mapbox GL Draw Buttons */
.mapboxgl-ctrl-group.mapboxgl-ctrl button {
  background-color: black; /* Set button background to black */
  border: none; /* Remove any default borders */
  width: 29px;
  height: 29px;
}

/* Mapbox GL Draw Button Icons (CORRECTED) */
.mapboxgl-ctrl-group.mapboxgl-ctrl button .mapboxgl-ctrl-icon {
background-color: transparent; /* Remove default icon background */
}

.mapboxgl-ctrl-group.mapboxgl-ctrl button .mapboxgl-ctrl-icon:before {
color: white; /* Set icon color to white */
}

.mapboxgl-ctrl-group.mapboxgl-ctrl button:hover {
background-color: rgb(80, 78, 78); /* Slightly lighter black on hover */
}

/* Mapbox GL Draw Active Button */
.mapboxgl-ctrl-group.mapboxgl-ctrl button.mapbox-gl-draw-active {
background-color: rgb(80, 78, 78); /* Slightly lighter black when active */
}