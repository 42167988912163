/* src/App.css */

/* Import Supreme font using @font-face */
@font-face {
  font-family: 'Supreme';
  src: url('./fonts/Supreme-Regular.woff2') format('woff2'),
       url('./fonts/Supreme-Regular.woff') format('woff'); /* Optional */
  font-weight: 400; /* Regular weight */
  font-style: normal;
}

@font-face {
  font-family: 'Supreme';
  src: url('./fonts/Supreme-Bold.woff2') format('woff2'),
       url('./fonts/Supreme-Bold.woff') format('woff');
  font-weight: 700; /* Bold weight */
  font-style: normal;
}

@font-face {
  font-family: 'Supreme';
  src: url('./fonts/Supreme-Italic.woff2') format('woff2');
  font-weight: 400; /* Regular weight */
  font-style: italic;
}

* {
  margin: 0;
 
  
 
}

body {
  background-color: #000000; /* Dark black background for the entire page */
 
}

/* Thin Scrollbar Styles */
body::-webkit-scrollbar,
.App::-webkit-scrollbar,
.cl-modalContent::-webkit-scrollbar {
  width: 8px;  /* Adjust width as desired */
}

body::-webkit-scrollbar-track,
.App::-webkit-scrollbar-track,
.cl-modalContent::-webkit-scrollbar-track {
  background: #222; /* Darker track color */
}

body::-webkit-scrollbar-thumb,
.App::-webkit-scrollbar-thumb,
.cl-modalContent::-webkit-scrollbar-thumb {
  background-color: #555; /* Slightly lighter thumb color */
  border-radius: 4px; /* Rounded corners for the thumb */
  border: 2px solid #222; /* Match thumb border with track color */
}

body::-webkit-scrollbar-thumb:hover,
.App::-webkit-scrollbar-thumb:hover,
.cl-modalContent::-webkit-scrollbar-thumb:hover {
  background-color: #777; /* Slightly brighter on hover */
}

.App {
  font-family: 'Supreme', sans-serif; /* Apply Supreme, fallback to sans-serif */
  text-align: center;
  overflow-y: auto;
  background-color: #171717; /* Slightly lighter background for the main app content */
 

  height: 99vh


 
}

/* Example usage for headings */
.App h1, .App h2, .App h3 {
  font-family: 'Supreme', sans-serif;
  font-weight: 700; /* Use Supreme Bold for headings */
}

/* Styling for Clerk modal container (and similar containers) */
.clerk-modal-container,
.signup-page-container {
  display: flex;
  justify-content: center;
  align-items: center;

}

/* Adjust Clerk's internal modal styles */
.clerk-modal-container .cl-modalContent, /* Targeting the content area of the Clerk modal */
.signup-page-container .cl-modalContent,
.cl-signUp, /* Targeting the signup component */
.cl-signIn { /* Targeting the signin component */
  border-radius: 12px; /* Apply rounded corners to Clerk's modal content */
  background-color: #171717; /* Match the background color with your app */
  max-height: 70vh; /* Adjust as needed. Limits modal height to 90% of viewport height*/

} 