/* Engine.css */
.engine-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
    background-color: #000;
    color: #fff;
    padding: 20px;
    overflow-x: hidden;
    min-height: 100vh; /* Ensure full viewport height */
    position: relative; /* To contain sign-in modal */
}

.engine-page { /* Example of page specific styling, if needed */

}

.engine-heading-container {
    margin-bottom: 30px;
    text-align: center; /* Center the heading container */
    width: 100%; /* Take full width to enable space-between */
    display: flex; /* Use flexbox for alignment */
    justify-content: space-between; /* Space between left and right parts */
    align-items: center; /* Vertically align items in header */
    /* flex-direction: column; Removed column direction */
}

.engine-heading-left {
    text-align: left; /* Align text content within the container */
    width: auto; /* Adjust width to content */
    display: flex;
    justify-content: flex-start; /* Align items to the start (left) */
    align-items: center;
    position: relative; /* To position status icon */
    gap: 10px; /* Space between icon and heading */
}

.engine-main-heading {
    font-size: 32px; /* Reduced font size */
    font-weight: 700;
    margin-bottom: 0; /* Removed margin-bottom */
    color: white;
    letter-spacing: -1.5px;
    line-height: 1.2;
    text-align: left; /* Ensure heading text is left-aligned */
    max-width: 100%; /* Ensure it doesn't overflow container */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.engine-loading-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 50vh; /* Center loader in viewport */
    background-color: transparent; /* Make loading screen background transparent */
}

.engine-loading-text {
    margin-top: 20px;
    font-size: 1.2em;
    color: #A0A0A0;
}

.engine-error-container {
    text-align: center;
    padding: 30px;
    border: 1px solid #f44336;
    border-radius: 8px;
    margin-top: 30px;
    background-color: #171717; /* Match background color to other containers */
}

.engine-error-heading {
    font-size: 1.8em;
    color: #f44336;
    margin-bottom: 10px;
}

.engine-error-message {
    color: #A0A0A0;
    margin-bottom: 8px;
}

.engine-assets-container {
    width: 100%;
    max-width: 1200px; /* Adjust as needed */
    margin-top: 20px;
}

.engine-assets-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.engine-assets-title {
    font-size: 2em;
    color: #fff;
}

.engine-download-all-button, .engine-share-button {
    background-color: #F97316;
    color: white;
    border: none;
    padding: 8px; /* Reduced padding */
    border-radius: 8px;
    cursor: pointer;
    font-size: 1em;
    display: flex;
    align-items: center;
    justify-content: center; /* Center icon */
    gap: 5px;
    transition: background-color 0.3s ease;
    width: 36px; /* Fixed width */
    height: 36px; /* Fixed height */
}

.engine-download-all-button:hover, .engine-share-button:hover {
    background-color: #F59E0B;
}

.engine-assets-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust minmax as needed */
    gap: 20px;
}

/* Styles for single asset layout */
.engine-assets-grid-single {
    display: flex;
    justify-content: center; /* Center horizontally */
}

.engine-assets-grid-single .engine-asset-item {
    max-width: 500px; /* Increased max width for single item */
    width: 100%;
}
.engine-assets-grid-single .engine-asset-media {
    aspect-ratio: 16/9; /* Wider aspect ratio for single item */
}


/* Styles for 2-3 assets layout */
.engine-assets-grid-few {
    display: flex;
    justify-content: center; /* Center horizontally */
    gap: 20px;
}

.engine-assets-grid-few .engine-asset-item {
    flex: 1; /* Distribute space evenly */
    max-width: 400px; /* Limit max width for each item */
}
.engine-assets-grid-few .engine-asset-media {
    aspect-ratio: 1/1; /* Square aspect ratio for few items */
}


.engine-asset-item {
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
}

.engine-asset-item:hover {
    transform: scale(1.05);
    z-index: 5;
}

.engine-asset-media-container {
    position: relative;
    display: block;
}

.engine-asset-media {
    display: block;
    width: 100%;
    height: auto;
    aspect-ratio: 1/1; /* Maintain square aspect ratio */
    object-fit: cover;
}

.engine-asset-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 8px;
}

.engine-asset-item:hover .engine-asset-overlay {
    opacity: 1;
}

.engine-video-icon-overlay, .engine-download-icon-overlay, .engine-expand-icon-overlay {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 5px;
    transition: background-color 0.3s ease;
}

.engine-video-icon-overlay:hover, .engine-download-icon-overlay:hover, .engine-expand-icon-overlay:hover {
    background-color: rgba(0, 0, 0, 0.9);
}

.engine-video-play-button {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
    transform: scale(0.7);
}

.engine-video-overlay-icon, .engine-download-overlay-icon, .engine-expand-overlay-icon {
    font-size: 1.2em;
    color: #F97316;
}

.engine-asset-name-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px;
    text-align: center;
    font-size: 0.9em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.engine-no-results-container {
    text-align: center;
    padding: 40px;
    background-color: #171717;
    border-radius: 8px;
    margin-top: 30px;
}

.engine-no-results-text {
    font-size: 1.2em;
    color: #A0A0A0;
}

/* Custom Loader (Spinner) - Reused from Home.css but namespaced */
.engine-custom-loader {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #F97316;
    width: 60px; /* Increased size for Engine page */
    height: 60px; /* Increased size for Engine page */
    animation: engine-spin 1.5s linear infinite; /* Namespaced animation name */
    margin: 30px 0; /* Added margin around the loader */
    border-color: rgba(255, 255, 255, 0.1); /* Make background transparent-ish */
    border-top-color: #F97316; /* Orange color for loader */
}

@keyframes engine-spin { /* Namespaced animation */
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
/* Message Log Item - Reused and adjusted from Home.css */
/* REMOVED MESSAGE LOG ITEM STYLES */

.engine-message-content {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-grow: 1;
    font-size: 1.1em; /* Slightly larger font size for messages */
}

.engine-message-status-icons {
    display: flex;
    align-items: center;
    margin-left: 20px; /* Increased margin for status icons */
    gap: 10px;
}

.engine-message-progress-circle {
    margin-right: 10px;
    width: 24px; /* Slightly larger loader */
    height: 24px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-left-color: #007bff;
    border-radius: 50%;
    animation: engine-spin 1s linear infinite; /* Reusing namespaced animation */
    display: inline-block;
    vertical-align: middle;
}

.engine-send-button-icon {
    font-size: 18px;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.engine-pending-thumbnail-container {
    position: relative;
}

.engine-welcome-asset-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.engine-welcome-asset-circle-loader {
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #F97316;
    width: 24px;
    height: 24px;
    animation: engine-spin 1.5s linear infinite; /* Reusing namespaced animation */
}

.engine-thumbnail {
    height: 100%; /* Make sure thumbnails fill their container */
    width: 100%;
    object-fit: cover; /* Ensure images cover the area, may crop */
}

.engine-thumbnail-video {
    object-fit: cover;
    height: 100%;
}

.engine-download-icon-overlay {
    top: auto; /* Reset top */
    bottom: 5px; /* Position at the bottom */
    left: 5px; /* Align to the left */
    border-radius: 8px 0 0 0; /* Rounded top-right corner */
    background-color: rgba(0, 0, 0, 0.5);
    padding: 8px;
    width: auto; /* Adjust width to content */
    height: auto; /* Adjust height to content */
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.engine-expand-icon-overlay {
    top: auto; /* Reset top */
    bottom: 5px; /* Position at the bottom */
    right: 5px; /* Align to the right */
    border-radius:  0 0 8px 0; /* Rounded top-left corner */
    background-color: rgba(0, 0, 0, 0.5);
    padding: 8px;
    width: auto; /* Adjust width to content */
    height: auto; /* Adjust height to content */
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Sign-in Modal Styles - Namespaced for Engine.js */
.engine-signin-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 101; /* Higher than other engine elements, but less than modals in Home.js if needed */
}

.engine-signin-modal-content {
    background-color: #171717;
    border-radius: 12px;
    padding: 20px;
    width: 80%;
    max-width: 400px;
    position: relative;
    text-align: center;
}

.engine-signin-modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 1.2em;
    cursor: pointer;
}

.engine-signin-modal-title {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #fff;
}

.engine-signin-modal-subtitle {
    color: #A0A0A0;
    margin-bottom: 20px;
}

.engine-signin-modal-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.engine-signin-modal-signin-button,
.engine-signin-modal-signup-button {
    padding: 10px 20px;
    border-radius: 8px;
    text-decoration: none;
    color: #fff;
    background-color: #3D3D3D;
    cursor: pointer;
    display: block;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
}

.engine-signin-modal-signup-button {
    background-color: #fff;
    color: #000;
}

/* Notification Styles */
.engine-notification {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #F97316;
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    z-index: 1000;
    opacity: 0.9;
}

/* Asset Modal Styles */
.engine-asset-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Darker overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 102; /* Higher than sign-in modal */
}

.engine-asset-modal-content {
    background-color: #171717;
    border-radius: 12px;
    padding: 20px;
    max-width: 90%; /* Adjust as needed */
    max-height: 90%; /* Adjust as needed */
    overflow: auto;
    position: relative; /* For close button positioning */
}

.engine-asset-modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 1.5em;
    cursor: pointer;
}

.engine-asset-modal-media {
    display: block;
    max-width: 100%;
    max-height: 80vh; /* Limit media height */
    margin: 0 auto; /* Center media */
}
.engine-asset-modal-video {
    max-width: 100%;
    max-height: 80vh;
    display: block;
    margin: 0 auto;
}
.engine-asset-modal-audio {
    width: 100%; /* Make audio player fill modal width */
    margin-top: 20px; /* Add some space above audio player */
}

.engine-status-icon {
    position: relative; /* Position relative to engine-heading-left */
    font-size: 2em; /* Size of the icon */
    color: #F97316; /* Orange color or green for checkmark */
    z-index: 1; /* Ensure it's above other header elements */
}

.engine-status-icon.engine-status-completed {
    color: green; /* Green color for completed checkmark */
}

.engine-auth-error-card {
    text-align: center;
    padding: 40px;
    background-color: #171717;
    border-radius: 8px;
    margin-top: 30px;
}

.engine-auth-error-text {
    font-size: 1.2em;
    color: #fff; /* Or #A0A0A0 for softer text */
    margin-bottom: 20px;
}

.engine-auth-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.engine-auth-button {
    padding: 10px 20px;
    border-radius: 8px;
    text-decoration: none;
    color: #fff;
    background-color: #3D3D3D;
    cursor: pointer;
    display: inline-block; /* For horizontal button layout */
}

.engine-auth-button.signup {
    background-color: #fff;
    color: #000;
}