.sidebar {
  width: 250px;
  background-color: #f2f2f2; /* Slightly grey background */
  padding: 20px;
  border-right: 1px solid #cccccc; /* Adjusted border color */
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease; 
}

.sidebar.closed {
  width: 80px; 
  padding: 15px; 
}

.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.brand-logo {
  display: flex;
  align-items: center;
}

.sidebar.closed .brand-logo {
  justify-content: center;
  margin-bottom: 0px;
}

.brand-logo img {
  height: 30px;
  margin-right: 10px;
}

.sidebar.closed .brand-logo img {
  margin-right: 0; 
}

.brand-logo span {
  font-weight: bold;
  font-size: 1.2em;
}

.sidebar.closed .brand-logo span {
  display: none; 
}

.toggle-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 1.4em;
  line-height: 1;
}

.open-sidebar-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    font-size: 1.4em;
    line-height: 1;
    margin-top: auto; 
    margin-bottom: 20px;
  
}

.sidebar.open .toggle-button {
  margin-left: auto; 
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar li {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.sidebar li:hover {
  background-color: #e8e8e8; /* Adjusted hover color */
}

.sidebar li.active {
  background-color: #ffe0b2; /* Light orange background */
}

.sidebar li.active .sidebar-link{
    color: #ff9800; /* Orange text color for active link */
}

.sidebar-link {
  color: black;
  text-decoration: none;
  display: flex; 
  align-items: center; 
  width: 100%; 
}

.sidebar li i {
  margin-right: 10px;
  width: 20px;
}

.sidebar h4 {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #555; /* Darker grey for headings */
}

.add-project {
  margin-top: 10px;
}

.icon {
  margin-right: 10px;
  font-size: 20px;
}

.sidebar.closed .icon {
  margin: 0;
  font-size: 24px; 
}

.sidebar.closed ul,
.sidebar.closed h4,
.sidebar.closed .sign-out-container {
  display: none; 
}

.closed-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.closed-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.closed-icons li {
  padding: 10px 0; 
  width: 100%; 
  display: flex;
  flex-direction: column; 
  align-items: center; 
}

.closed-icons li.active {
  background-color: transparent; /* No background when closed */
}

.closed-icons li.active .sidebar-link .icon{
    color: #ff9800; /* Orange icon for active link when closed */
}

.icon-label {
  font-size: 12px; 
  margin-top: 5px; 
  text-align: center; 
}

/* UserButton Container */
.user-button-container {
  margin-top: auto; /* Push to the bottom */
  margin-bottom: 20px; /* Add margin */
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}

.user-name {
  font-size: 14px;
  color: #333;
}

.sidebar.closed .user-button-container,
.sidebar.closed .user-name {
  display: none;
}

/* UserButton dropdown */
.cl-user-button-popup {
    position: absolute;
    left: 0;
    bottom: calc(100% + 10px);
    width: 250px;
    z-index: 9999;
}

.sidebar.closed .cl-user-button-popup {
  left: -100px;
  bottom: auto;
  top: 100%;
}