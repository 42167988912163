.chat-container {
    display: flex;
    height: 100vh;
    background-color: #3D3D3D;
    font-family: sans-serif;
   overflow: hidden;
}

.chat-list {
    width: 300px;
    padding: 20px;
    border-right: 1px solid #555;
    background-color: #2a2a2a;
    display: flex;
    flex-direction: column;
}
.chat-header{
   display: flex;
   flex-direction: column;
}

.chat-tabs{
   display: flex;
   margin-top: 10px;

}

.chat-tabs button {
      background-color: transparent;
     border: none;
      padding: 10px 15px;
     cursor: pointer;

}
.chat-tabs button.active-tab {
        background-color:  #2e8b57;
        color: white;
        border-radius: 5px;
}
.chat-tabs button:hover {
  opacity: 0.8
}

.search-box {
   margin-top: 20px;
    margin-bottom: 10px;
}

.search-box input {
    width: 100%;
     padding: 10px;
     border: 1px solid #555;
     background-color: #444;
      color: white;
       border-radius: 15px;
    box-sizing: border-box;
}

.chat-item {
     display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #555;
    transition: background-color 0.2s;
}
.chat-item:hover {
      background-color: #444;
}


.chat-item.active {
    background-color: #555;
}
.chat-item-left {
     display: flex;
    align-items: center;
}
.chat-item-right {
      flex: 1;
     padding-left: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
}
.user-avatar {
    width: 30px;
    height: 30px;
    background-color: #777;
    border-radius: 50%;
}

.chat-window {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.chat-window-header {
    background-color: #2a2a2a;
    padding: 15px;
    border-bottom: 1px solid #555;
      display: flex;
    align-items: center;
   justify-content: space-between;
}
.chat-user-info {
    display: flex;
   align-items: center;
}

.call-button {
  display: flex;
    gap: 10px;
}
.call-button img:hover {
  opacity: 0.8
}
.chat-messages {
    flex: 1;
    padding: 20px;
     overflow-y: auto;
    display: flex;
    flex-direction: column;
}
.chat-messages::-webkit-scrollbar {
    width: 5px;
}

.chat-messages::-webkit-scrollbar-thumb {
    background-color: #555;
     border-radius: 5px;

}


.message {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.message.sent {
     align-items: flex-end;
}

.message.received {
    align-items: flex-start;
}
.message-text {
    padding: 10px 15px;
    border-radius: 15px;
    max-width: 70%;
      word-wrap: break-word;
}

.chat-input {
    display: flex;
    padding: 10px;
    background-color: #2a2a2a;
    border-top: 1px solid #555;
}

.chat-input input {
    flex: 1;
      padding: 10px;
    border: 1px solid #555;
      background-color: #444;
    margin-right: 10px;
     border-radius: 25px;
    color: white
}
.send-button {
       background-color: #2e8b57;
       border: none;
      padding: 10px;
     cursor: pointer;
     border-radius: 5px;

}
.send-button:hover {
  opacity: 0.8
}