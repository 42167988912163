/* src/components/Workspace.css */
.workspace-container {
  display: flex;
  height: 100vh;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  margin-left: 250px; /* Offset for fixed sidebar */
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.top-bar-right {
  display: flex;
  align-items: center;
}

.top-bar-right .dropdown {
  margin-right: 15px;
  position: relative;
}

.top-bar-right .dropdown span {
  cursor: pointer;
}

.top-bar-right .dropdown i {
  margin-left: 5px;
}

.new-map-button {
  background-color: #ff5733; /* Example color - adjust as needed */
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.map-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
}

.map-card {
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  position: relative; /* For absolute positioning of children */
  cursor: pointer; /* Indicate that the card is clickable */
}

.map-image-container {
  height: 200px;
  overflow: hidden;
  position: relative; /* For absolute positioning of children */
}

.map-card img {
  width: 100%;
  height: auto;
  object-fit: cover;
  vertical-align: middle; /* Prevents extra space below the image */
}

.map-title-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 10px;
}

.map-title-overlay h3 {
  margin: 0;
  font-size: 1em;
}

.map-icons {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  opacity: 0; /* Icons hidden by default */
  transition: opacity 0.3s ease;
  pointer-events: none; /* Allow clicks to pass through when hidden */
}

.map-card:hover .map-icons {
  opacity: 1; /* Icons visible on hover */
  pointer-events: auto; /* Enable clicks when visible */
}

.delete-icon,
.navigate-icon {
  color: white;
  cursor: pointer;
  z-index: 2; /* Ensure icons are above other elements */
  font-size: 1.2rem; /* Adjust icon size as needed */
}

.navigate-icon {
  align-self: flex-end;
}

/* Media query for larger screens */
@media (min-width: 850px) {
  .map-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}