/* NeoAsset.css */
/* Import styles from Neo.css to maintain consistency */
@import url('./Neo.css');

/* Override or extend styles as needed for NeoAsset within the card structure */
.neo-asset-container {
    /* Inherits container styles from Neo.css which centers and sets background */
    min-height: auto;
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center card horizontally */
    justify-content: center; /* Center card vertically */
}

.neo-asset-card {
    /* Inherits card styles from Neo.css */
    width: 100%;
    max-width: 500px; /* Keep max-width same as original Neo card */
    height: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden; /* Keep overflow hidden on card itself */
    transform: translateY(100%); /* Start position for animation from bottom */
    animation: slideUpCard 0.5s ease-out 0.3s forwards;
    transform-origin: bottom center; /* Animation from bottom center */
}

@keyframes slideUpCard {
    to {
        transform: translateY(0);
    }
}

.neo-asset-header {
    padding: 20px;
    text-align: left;
}

.neo-asset-brand {
    color: #FF6700;
    font-weight: bold;
    font-size: 1.8em;
    display: block;
    margin-bottom: 5px;
}

.neo-asset-carousel-container {
    flex-grow: 0; /* Prevent vertical growth */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden; /* Keep horizontal overflow hidden */
    overflow-y: auto; /* Enable vertical scrolling if content overflows */
    opacity: 0;
    animation: fadeInCarousel 0.5s ease-in-out 2.8s forwards;
    max-height: calc(100% - 150px); /* Limit vertical height within card, adjust 150px based on header/footer */
}

@keyframes fadeInCarousel {
    to {
        opacity: 1;
    }
}

.neo-asset-carousel-loading-container {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    animation: fadeOutLoader 0.3s ease-out 2.5s forwards;
}

@keyframes fadeOutLoader {
    to {
        opacity: 0;
        display: none;
    }
}


.neo-asset-carousel {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    gap: 20px;
    padding: 20px;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    height: auto; /* Let carousel height be determined by content */
    max-height: none; /* Remove max-height restriction on carousel itself */
}

.neo-asset-carousel::-webkit-scrollbar {
    height: 8px;
    background-color: #333;
}

.neo-asset-carousel::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 4px;
}

.neo-asset-item {
    min-width: 100%; /* Ensure each item takes full carousel width */
    max-width: 100%; /* Ensure each item takes full carousel width */
    height: auto; /* Let item height be determined by content */
    max-height: none; /* Remove max-height restriction on item */
    scroll-snap-align: start;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #222;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.neo-asset-media-container {
    width: 100px; /* Fixed width to 100px - IMPORTANT CHANGE */
    height: 100px; /* Fixed height to 100px - IMPORTANT CHANGE */
    max-width: none; /* Remove max-width restriction */
    max-height: none; /* Remove max-height restriction */
    aspect-ratio: 1 / 1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}


.neo-asset-item img,
.neo-asset-item video {
    max-width: 100%;
    max-height: 100%;
    display: block;
    border-radius: 8px;
    object-fit: contain; /* Keep object-fit: contain to scale image within 100x100 square */
}


.neo-asset-footer {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
}

.neo-asset-close-button {
    padding: 10px 20px;
    border-radius: 8px;
    background-color: #FF6700;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
}

.neo-asset-close-button:hover {
    background-color: #d65500;
}

/* Custom CSS Loader - from Neo.css - Ensure it's orange */
.neo-asset-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Take full height of carousel container */
}

.neo-asset-loader {
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top: 3px solid #FF6700; /* Orange color */
  border-radius: 50%;
  width: 40px; /* Increased size for visibility */
  height: 40px; /* Increased size for visibility */
  animation: neo-spin 1s linear infinite; /* Re-use neo-spin animation */
}


/* Download Link Style */
.neo-asset-download-link {
  color: rgba(255, 255, 255, 0.692);
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.397);
  background: rgba(255, 255, 255, 0.068);
  overflow: hidden;
  font-size: 0.9rem;
  font-weight: 600;
  gap: 8px;
  border-radius: 5px;
  margin: 15px 5px 5px 5px; /* Added top margin to separate from asset */
  transition: 0.2s;
  border: 1px solid transparent;
  text-decoration: none; /* Remove underline from link */
}

.neo-asset-download-link:hover {
  border-color: rgba(255, 255, 255, 0.623);
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.144),
    rgba(255, 255, 255, 0.247),
    rgba(255, 255, 255, 0.39)
  );
  box-shadow: 0 6px rgba(255, 255, 255, 0.623);
  transform: translateY(-6px);
}

.neo-asset-download-link:active {
  transform: translateY(2px);
  box-shadow: none;
}