.mediaPage {
  color: #3d3d3d;
  padding-left: 2rem;
  padding-right: 2rem;
  max-height: 94vh;
  margin-top: 0rem;
  flex-grow: 1;
  transition: margin-left 0.3s;
  overflow: hidden;
  background-color: black;
  margin-bottom: 0.5
}

.mediaPage .topBar {
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center;
  margin-bottom: 2rem;
  flex-direction: column; /* Stack upload and search on smaller screens */
}

.mediaPage .topBar .uploadSection {
  margin-right: 0; /* Remove right margin */
  margin-bottom: 0rem; /* Add bottom margin to separate from search on smaller screens */
  margin-top: 0.5rem; 
  align-self: flex-start; /* Align upload icon to the start (left) */
}

.mediaPage .topBar .searchBarContainer {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center; /* Center search elements */
  width: 100%; /* Take full width to center effectively */
  max-width: 600px; /* Limit max width of search area if needed */
}

.mediaPage .topBar .searchTabs {
  display: flex;
  margin-right: 1rem;
}

.mediaPage .topBar .searchTabs .searchTab {
  padding: 0.5rem 0.75rem;
  border-radius: 20px;
  cursor: pointer;
  color: white;
  background-color: #222;
  margin-right: 0.5rem;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s;
}

.mediaPage .topBar .searchTabs .searchTab.active,
.mediaPage .topBar .searchTabs .searchTab:hover {
  background-color: #FF6700; /* Updated orange color */
  color: black;
}

.mediaPage .topBar .searchTabs .searchTab:last-child {
  margin-right: 0;
}

.mediaPage .topBar .searchBar {
  width: 50%;
  max-width: 400px;
}

.mediaPage .topBar .searchBar .searchInput {
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: #333;
  color: white;
  border: 2px solid #FF6700; /* Updated orange border */
  border-radius: 20px;
  font-size: 0.9rem;
}

.mediaPage .topBar .searchBar .searchInput::placeholder {
  color: #666;
}

.mediaPage .topBar .uploadSection .uploadIcon {
  font-size: 2rem;
  color: #FF6700; /* Updated orange color */
  cursor: pointer;
  transition: color 0.2s;
}

.mediaPage .topBar .uploadSection .uploadIcon:hover {
  color: #CC5200; /* Darker orange hover color */
}

.mediaPage .emptyStateCard {
  background-color: black;
  color: white;
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
  margin: 0 auto;
  max-width: 600px;
  margin-bottom: 2rem;
}

.mediaPage .emptyStateCard .emptyStateTitle {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: white;
}

.mediaPage .emptyStateCard .emptyStateText {
  font-size: 1rem;
  line-height: 1.5;
  color: white;
}

.mediaPage .mediaGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Default 4 columns */
  gap: 1rem;
  max-height: 450px; /* Increased max height */
  overflow-y: auto;
  padding-right: 1rem;
}

.mediaPage .mediaGrid::-webkit-scrollbar {
    width: 8px;
}

.mediaPage .mediaGrid::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 4px;
}

.mediaPage .mediaGrid::-webkit-scrollbar-track {
    background-color: #222;
    border-radius: 4px;
}

.mediaPage .mediaCard {
  background-color: #333333;
  color: white;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  position: relative;
  height: 200px;
}

.mediaPage .mediaCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.mediaPage .mediaCard:hover .deleteIconContainer,
.mediaPage .mediaCard:hover .editIconContainer {
  opacity: 1;
}

.mediaPage .mediaCard .deleteIconContainer {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 10;
}

.mediaPage .mediaCard .deleteIconContainer .deleteIcon {
  color: #d9534f;
  font-size: 1.2rem;
}

.mediaPage .mediaCard .deleteIconContainer .deleteIcon:hover {
  color: #c9302c;
}

.mediaPage .mediaCard .editIconContainer {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 10;
}

.mediaPage .mediaCard .editIconContainer .editIcon {
  color: white;
  font-size: 1.2rem;
}

.mediaPage .mediaCard .editIconContainer .editIcon:hover {
  color: #333;
}

.mediaPage .mediaCard .mediaIconContainer {
  width: 100%;
  height: 145px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.mediaPage .mediaCard .mediaIcon {
  font-size: 3rem;
  color: #FF6700; /* Updated orange color */
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.mediaPage .mediaCard .imageContainer {
  width: 145px;
  height: 145px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.mediaPage .mediaCard .imageContainer .mediaImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 8px;
}

.mediaPage .mediaCard .mediaName {
  font-size: 0.9rem;
  color: white;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  margin-top: 0.5rem;
  max-height: 2.5rem;
}

.mediaPage .loadingIndicator {
  text-align: center;
  margin-top: 2rem;
  font-size: 2rem;
  color: #FF6700; /* Updated orange color */
}

.mediaPage .loadMoreButton {
  display: block;
  width: 200px;
  margin: 2rem auto;
  padding: 0.7rem 1.5rem;
  background-color: #FF6700; /* Updated orange color */
  color: black;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
}

.mediaPage .loadMoreButton:hover {
  background-color: #CC5200; /* Darker orange hover color */
}

.mediaPage .modalOverlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.mediaPage .modalOverlay.visible {
  display: flex;
}

.mediaPage .modalContent {
  background-color: black;
  color: white;
  border-radius: 8px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
}

.mediaPage .modalContent .modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #ccc;
}

.mediaPage .modalContent .modalHeader .modalTitle {
  margin: 0;
  font-size: 1.2rem;
  white-space: nowrap;
}

.mediaPage .modalContent .modalHeader .closeButton {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.mediaPage .modalContent .modalBody {
  padding: 1rem;
}

.mediaPage .modalContent .modalBody .uploadTypeButtons {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.mediaPage .modalContent .modalBody .uploadTypeButtons button {
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  background-color: white;
  color: #000;
  cursor: pointer;
  transition: background-color 0.2s;
}

.mediaPage .modalContent .modalBody .uploadTypeButtons button.active {
  background-color: #FF6700; /* Updated orange color */
  color: #000;
}

.mediaPage .modalContent .modalBody .uploadTypeButtons button:hover {
  background-color: #CC5200; /* Darker orange hover color */
}

.mediaPage .modalContent .modalBody .uploadInfo {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  align-items: center;
}

.mediaPage .modalContent .modalBody .uploadInfo .assetNameInput {
  flex-grow: 1;
  padding: 0.5rem;
  background-color: #eee;
  color: black;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 0.9rem;
}

.mediaPage .modalContent .modalBody .uploadInfo .selectCategory {
  padding: 0.5rem;
  background-color: #eee;
  color: #000;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 0.9rem;
}

.mediaPage .modalContent .modalBody .uploadInfo .selectCategory option {
  font-size: 0.9rem;
}

.mediaPage .modalContent .modalBody .dropzone {
  border: 2px dashed #FF6700; /* Updated orange border */
  color: white;
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.2s;
  margin-bottom: 1rem;
  min-height: 120px;
}

.mediaPage .modalContent .modalBody .dropzone.dragging {
  border-color: #CC5200; /* Darker orange hover color */
}

.mediaPage .modalContent .modalBody .dropzone p {
  color: black;
  margin: 0;
  font-size: 0.9rem;
}

.mediaPage .modalContent .modalBody .imagePreview {
  margin-bottom: 1rem;
}

.mediaPage .modalContent .modalBody .imagePreview img {
  max-width: 100px;
  max-height: 100px;
  object-fit: contain;
}

.mediaPage .modalContent .modalBody .uploadButton {
  background-color: #FF6700; /* Updated orange color */
  color: black;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s;
  width: auto;
  margin-top: 1rem;
  font-size: 0.9rem;
}

.mediaPage .modalContent .modalBody .uploadButton:hover {
  background-color: #CC5200; /* Darker orange hover color */
}

.mediaPage .modalContent .modalBody .uploadButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.mediaPage .modalContent .modalBody .comingSoon {
  text-align: center;
  padding: 2rem;
}

.mediaPage .modalContent .modalBody .comingSoon .backButton {
  margin-top: 1rem;
  background-color: #eee;
  color: #000;
  padding: 0.5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.mediaPage .modalContent .modalBody .comingSoon .backButton:hover {
  background-color: #CC5200; /* Darker orange hover color */
}

.mediaPage .modalContent .uploadStatus {
  margin-top: 1rem;
  font-size: 1rem;
  padding: 1rem;
  text-align: center;
}

.mediaPage .modalContent .uploadStatus.success {
  color: #3c763d;
}

.mediaPage .modalContent .uploadStatus.error {
  color: #a94442;
}

.mediaPage .previewModal {
  max-width: 600px;
}

.mediaPage .previewModal .previewImageContainer {
  text-align: center;
  padding-right: 0;
}

.mediaPage .previewModal .previewImage {
  max-width: 100%;
  max-height: 60vh;
  object-fit: contain;
}

.mediaPage .previewModal .audioPlayerContainer {
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
}

.mediaPage .previewModal .audioPlayerContainer .audioPlayer {
  width: 100%;
  margin-top: 1rem;
}

.mediaPage .previewModal .pdfPreview {
  width: 100%;
  height: 60vh;
  border: none;
}

.mediaPage .editModal {
  max-width: 500px;
}

.mediaPage .editModal .editForm .editInput,
.mediaPage .editModal .editForm .editTextArea {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  background-color: #eee;
  color: black;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.mediaPage .editModal .editForm .editTextArea {
  min-height: 120px;
  resize: vertical;
}

.mediaPage .editModal .editForm .saveButton {
  background-color: #FF6700; /* Updated orange color */
  color: black;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s;
}

.mediaPage .editModal .editForm .saveButton:hover {
  background-color: #CC5200; /* Darker orange hover color */
}

.mediaPage .modalContent.previewModal,
.mediaPage .modalContent:not(.previewModal):not(.editModal) {
  max-width: 450px;
  padding-bottom: 2rem;
}

.sandbox-container {
  display: flex;
}

.emptyStateContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Responsive Grid adjustments */
@media (max-width: 960px) {
  .mediaPage .mediaGrid {
    grid-template-columns: repeat(3, 1fr); /* 3 columns on medium screens */
  }
}

@media (max-width: 768px) {
  .mediaPage .mediaGrid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on smaller screens */
  }
  .mediaPage .topBar .searchBar {
    width: 70%; /* Make search bar wider on smaller screens */
  }
}

@media (max-width: 576px) {
  .mediaPage .mediaGrid {
    grid-template-columns: repeat(1, 1fr); /* 1 column on extra small screens */
  }
  .mediaPage .topBar .searchBar {
    width: 90%; /* Even wider search bar on extra small screens */
  }
  .mediaPage .topBar .searchTabs {
    margin-right: 0.5rem; /* Reduce tab margin on extra small screens */
  }
  .mediaPage .topBar .searchTabs .searchTab {
     margin-right: 0.25rem; /* Reduce individual tab margin even more */
     padding: 0.4rem 0.6rem; /* Slightly reduce tab padding */
     font-size: 0.8rem; /* Slightly reduce tab font size */
  }
}