/* GameFeed.css */
.game-feed-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    color: white;
    padding: 20px;
    z-index: 10; /* Ensure it's above other content if needed */
    pointer-events: auto; /* Make sure it can receive events */
    margin-top: 20px;
}

.game-feed-header {
    display: flex;
    align-items: center;
    justify-content: center; /* Center content horizontally */
    width: 100%;
    margin-bottom: 20px;
    position: relative; /* To position back button */
}

.game-feed-header h2 {
    margin: 0;
    color: white;
    text-align: center; /* Ensure title text is centered */
    flex-grow: 1; /* Allow title to take up available space */
}

.game-feed-header .back-button {
    cursor: pointer;
    margin-right: 10px;
    border: 2px solid grey;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(61, 61, 61, 0.6);
    pointer-events: auto;
    position: absolute; /* Position back button to the left */
    left: 0;
}


.game-feed-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Responsive grid */
    gap: 20px;
    width: 100%;
    max-width: 1200px; /* Limit width if needed */
    justify-items: center; /* Center items in grid cells */
    max-height: calc(100vh - 150px); /* Adjust as needed to fit 2-3 rows and header */
    overflow-y: auto; /* Enable vertical scroll */
    padding-right: 10px; /* Space for scrollbar */
}

/* Hide scrollbar but keep functionality - Webkit browsers */
.game-feed-cards-container::-webkit-scrollbar {
    width: 8px; /* Adjust scrollbar width */
}

.game-feed-cards-container::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2); /* Scrollbar thumb color */
    border-radius: 10px;
}

.game-feed-cards-container::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1); /* Scrollbar track color */
    border-radius: 10px;
}


.game-feed-card {
    background-color: #333333;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%; /* Occupy full width of grid cell */
    max-width: 250px; /* Maximum width of the card */
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
    position: relative; /* For arrow positioning */
    pointer-events: auto;
}

.game-feed-card:hover {
    background-color: #555555;
}

.game-feed-thumbnail {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
    aspect-ratio: 16 / 9; /* Example aspect ratio */
    object-fit: cover;
}

.game-feed-title {
    font-size: 1em;
    text-align: center;
    margin-bottom: 25px; /* Space for the arrow */
    color: #eee;
}

.game-feed-card-arrow {
    position: absolute;
    right: 10px;
    bottom: 10px;
}

/* Loading state styles - reuse from Simulation.css if desired, or define here */
.game-feed-container.loading-container {
    justify-content: center; /* Center content vertically */
    align-items: center;
}

.game-feed-container.loading-container .loading-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #FF6700;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
    margin-bottom: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Error state styles */
.game-feed-container.error-container {
    text-align: center;
}

.game-feed-container.error-container button {
    background-color: #FF6700;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}