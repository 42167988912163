.legend {
    position: absolute;
    bottom: 20px;
    left: 20px;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 10; /* Ensure it's above the map */
    font-family: sans-serif;
  }
  
  .legend.minimized {
    width: auto;
    padding: 5px;
  }
  
  .legend-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .legend-title {
    font-weight: bold;
    margin-right: 10px;
  }
  
  .minimize-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    font-size: 16px;
  }
  
  .legend-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    justify-content: space-between; /* This will push the checkbox to the right */
  }
  
  .legend-color {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-right: 5px;
    border: 1px solid #ccc;
  }
  
  .legend-label {
    margin-right: auto; /* Allow label to take up available space */
  }
  
  .legend-icons {
    display: flex;
    align-items: center;
  }
  
  .legend-icons > * {
    margin-left: 10px; /* Space between icons */
  }
  
  .remove-button,
  .center-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    font-size: 12px;
    color: #888;
  }
  
  .remove-button:hover,
  .center-button:hover {
    color: black;
  }
  
  .tooltip-container {
    position: relative;
    display: inline-block;
    margin-left: 5px;
  }
  
  .tooltip-icon {
    color: #888;
    cursor: pointer;
  }
  
  .tooltip-text {
    visibility: hidden;
    width: 200px;
    background-color: #333;
    color: white;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -100px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }
  
  /* Styles for the checkbox */
  .legend-item input[type="checkbox"] {
    opacity: 0;
    position: absolute;
  }
  
  .legend-item input[type="checkbox"] + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }
  
  /* Checkbox box */
  .legend-item input[type="checkbox"] + label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 18px;
    height: 18px;
    background: white;
    border: 1px solid #888;
    border-radius: 3px;
  }
  
  /* Checkbox checkmark (hidden when unchecked) */
  .legend-item input[type="checkbox"]:checked + label:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 #888, 4px 0 0 #888, 4px -2px 0 #888, 4px -4px 0 #888, 4px -6px 0 #888, 4px -8px 0 #888;
    transform: rotate(45deg);
  }