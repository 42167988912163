.hub-container {
    background-color: black;
    min-height: 98vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    padding: 20px;
    font-family: 'Arial', sans-serif;
}

.hub-title {
    font-size: 3em;
    margin-bottom: 30px;
    background: linear-gradient(to right, #FF6700, #FFA500);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-align: center;
}

/* Search Bar Container */
.search-bar-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 80%;
    max-width: 800px;
}

/* Search bar in middle initially */
.search-bar-middle {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.search-bar-middle .mode-buttons {
    margin-bottom: 10px; /* Space between mode buttons and input in middle */
}


/* Move search bar to top when searching */
.search-bar-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: black;
    padding: 10px 20px;
    width: 100%;
    max-width: none;
    justify-content: flex-start; /* Align items to the start (left) */
    align-items: center;
    display: flex;
}

.search-bar-top-modes {
    margin-right: 10px; /* Keep spacing between buttons and input */
    margin-left: 20px; /* Add left margin to mode buttons in top bar */
}
.search-bar-top .search-form {
    flex-grow: 1; /* Take available space */
    margin-right: 20px; /* Add right margin to search form in top bar */
    max-width: 400px; /* Reduced max width of search form in top bar (half width) */
}


.mode-buttons {
    display: flex;
    margin-right: 10px;
}

.mode-button {
    background-color: #333;
    color: white;
    border: none;
    padding: 12px 15px;
    border-radius: 5px 0 0 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
    border-right: 1px solid #555;
    min-width: 50px;
    text-align: center;
}

.mode-button:last-child {
    border-radius: 0 5px 5px 0;
    border-right: none;
}

.mode-button.active {
    background-color: #FF6700;
}

.mode-button:hover {
    background-color: #555;
}


.search-form {
    display: flex;
    flex-grow: 1;
    width: 100%;
    max-width: 800px;
}

.search-input-gen {
    flex-grow: 1;
    padding: 12px 15px;
    border: 2px solid #FF6700; /* Orange border */
    border-radius: 50px; /* Circular ends */
    color: white; /* White text */
    font-size: 1em;
    background-color: black; /* Black background */
    margin-left: 0;
    max-width: 60%; /* Ensure input respects container max-width */
}

.search-input-gen::placeholder { /* Style placeholder text */
    color: #ddd; /* Lighter color for placeholder */
}


.error-message {
    color: #FF4500;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
}

/* Centered Loading Container */
.loading-container-centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}


.loading-message {
    margin-bottom: 10px;
    font-size: 1.1em;
    color: #FFA500;
    text-align: center;
}

.cancel-button {
    background-color: #d9534f;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9em;
    transition: background-color 0.3s ease;
    margin-top: 10px;
}

.cancel-button:hover {
    background-color: #c9302c;
}

.cancel-button:disabled {
    background-color: #f0ad4e;
    cursor: not-allowed;
}

.media-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    width: 90%;
    max-width: 1200px;
    margin-top: 20px;
    justify-content: center;
}

.media-item {
    border-radius: 10px;
    overflow: hidden;
    background-color: #333;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.media-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(255, 103, 0, 0.5);
}

.media-content {
    display: block;
    width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: cover;
}


/* Loader CSS */
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.spinner {
    border: 6px solid rgba(255, 103, 0, 0.3);
    border-top: 6px solid #FF6700;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}