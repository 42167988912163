/* Tastemaker.css */
.tastemaker-page {
    display: flex;
    height: 100vh;
    background-color: #000;
    color: #fff;
    overflow: hidden;
    margin-left: 0;
    transition: margin-left 0.5s ease;
    flex-direction: column; /* Ensure content flows vertically */
}

.tastemaker-content {
    flex-grow: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    padding-top: 20px; /* Reduced padding from top */
    max-width: 1200px; /* Example max width for content */
    margin: 0 auto; /* Center the content on the page */
    width: 100%; /* Take full width of parent */
}

.collage-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px; /* Reduced margin below header */
    position: relative;
    flex-wrap: wrap;
    gap: 10px;
}

.active-block-header {
    display: flex;
    align-items: baseline; /* Align items on baseline */
    gap: 20px;
    flex-wrap: wrap;
}


.active-block-title-area {
    font-size: 1.7em; /* Increased title font size */
    font-weight: bold;
    color: #fff;
    margin-right: 15px;
    line-height: 1; /* Reduced line height for title */
}


.teach-button {
    background-color: #1E293B;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.teach-button.active {
    background-image: linear-gradient(to right, #F97316, #F59E0B);
    color: #fff;
    box-shadow: 0 0 15px #F97316;
}


.teach-button:hover, .test-button:hover, .confirm-button:hover, .autopilot-button:hover {
    background-color: #3D3D3D;
}

.confirm-button {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    background-color: #4CAF50;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
    white-space: nowrap;
}

.confirm-button:hover {
    background-color: #66BB6A;
}


.upload-area {
    border: 2px dashed #A0A0A0;
    border-radius: 12px;
    padding: 40px 20px;
    text-align: center;
    cursor: pointer;
    color: #A0A0A0;
    transition: border-color 0.3s ease;
    margin-bottom: 20px;
    position: relative;
}

.upload-area.dragging-over {
    border-color: #F97316;
    color: #fff;
}

.upload-area.dragging-enabled {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.upload-area.dragging-disabled {
    display: none;
}


.upload-link {
    color: #F97316;
    text-decoration: none;
    cursor: pointer;
}

.selected-files-preview {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    justify-content: center;
    flex-wrap: wrap;
}

.file-preview-image { /* Style for image previews in upload area */
    max-width: 100px; /* Adjust as needed */
    max-height: 100px;
    border-radius: 8px;
}


.file-preview-name {
    background-color: #3D3D3D;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.9em;
}

.upload-error-message {
    color: #ff4d4d;
    margin-top: 10px;
}

.upload-success-message {
    color: lightgreen;
    margin-top: 10px;
}

.collage-container {
    width: 100%;
    position: relative; /* For positioning actions container */
}

.react-photo-collage--wrapper {
    border-radius: 12px;
    overflow: hidden; /* Clip corners for rounded border */
}

.react-photo-collage--photo {
    cursor: pointer; /* If you want images to be clickable */
    object-fit: cover; /* To ensure images cover the area */
    transition: transform 0.3s ease; /* Smooth hover effect */
}

.react-photo-collage--photo:hover {
    transform: scale(1.05); /* সামান্য scale up on hover */
}

.image-actions-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(10, 1fr); /* Example: 10 columns */
    grid-template-rows: repeat(10, 1fr);    /* Example: 10 rows */
    pointer-events: none; /* Ensure actions don't interfere with collage clicks */
    border-radius: 12px;
    overflow: hidden; /* Clip actions to collage bounds */
}

.collage-image-actions {
    position: relative; /* Needed for absolute positioning within grid item */
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease;
    pointer-events: auto; /* Make buttons interactive */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    width: 100%;
    height: 100%;
    z-index: 1; /* Ensure it's above the image */
}


.react-photo-collage--photo:hover + .image-actions-container .collage-image-actions,
.collage-image-actions:hover {
    opacity: 1; /* Show actions on image hover or action hover */
}


/* You'll need to adjust the selectors to correctly target each action overlay */
/* Example: targeting actions for each image - this might need JS coordination */
/* For now, a general overlay style */
/* .collage-image-actions {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px; /* Match collage border-radius */
/* } */


.image-preview-button, .image-delete-button {
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
    pointer-events: auto; /* Make sure buttons are interactive */
}

.image-preview-button:hover, .image-delete-button:hover {
    background-color: rgba(0, 0, 0, 0.9);
}
.image-preview-button svg, .image-delete-button svg {
    font-size: 1.2em;
}


.data-tab {
    /* Styles moved to sidebar-content.data-sidebar-content in CSS */
}

.data-tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.data-tab h3 {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 1.1em;
}

.generated-images-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 15px;
    max-height: calc(100vh - 400px);
    overflow-y: auto;
    overflow-x: hidden;
}

.generated-thumbnail {
    width: 100%;
    max-width: 200px;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.3s ease;
    display: block;
    object-fit: cover;
    aspect-ratio: 16/9;
}

.generated-thumbnail:hover {
    transform: scale(1.1);
}

.autopilot-button {
    width: auto;
    text-align: center;
    display: inline-block;
    box-sizing: border-box;
    padding: 10px 20px;
    margin-bottom: 10px;
    white-space: nowrap;
}


.preview-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.preview-modal-content {
    background-color: #171717;
    border-radius: 12px;
    padding: 20px;
    max-width: 90%;
    max-height: 90%;
    overflow: auto;
    position: relative;
}

.modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 1.2em;
    cursor: pointer;
}

.preview-image-full {
    display: block;
    max-width: 100%;
    height: auto;
}

.test-input-area {
    display: flex;
    margin-left: auto;
    align-items: center;
    gap: 10px;
}

.test-query-input {
    background-color: #3D3D3D;
    border: none;
    border-radius: 8px;
    padding: 8px 12px;
    color: #fff;
    font-size: 1em;
    outline: none;
    width: 200px;
}

.send-test-button {
    background-color: #505050;
    color: #fff;
    padding: 7px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease;
    border: none;
    cursor: pointer;
}

.send-test-button:hover {
    background-image: linear-gradient(to right, #F97316, #F59E0B);
    box-shadow: 0 0 10px #F97316;
}

.send-button-icon {
    font-size: 16px;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-loader {
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #F97316;
    width: 14px;
    height: 14px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.no-results-card {
    background-color: #3d3d3d;
    border-radius: 12px;
    padding: 30px;
    text-align: center;
    width: 40%;
    margin: 0 auto;
    display: block;
}

.no-results-title {
    font-size: 1.3em;
    color: #fff;
    margin-bottom: 10px;
}

.no-results-description {
    color: #A0A0A0;
}

/* Loader inside Learning Button */
.teach-button.learning .button-loader {
    border: 4px solid transparent;
    border-top-color: #fff;
    border-radius: 50%;
    width: 1em;
    height: 1em;
    animation: spin 1s linear infinite;
    display: inline-block;
    margin-right: 5px;
}

.teach-button.learning .cancel-learning-icon { /* Cancel icon style */
    margin-left: 5px;
    font-size: 0.8em;
    cursor: pointer; /* Make it obvious it's clickable */
}

.blocks-dropdown-container {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    gap: 10px;
}

.blocks-dropdown {
    background-color: #3D3D3D;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 8px 12px;
    font-size: 1em;
    outline: none;
    appearance: none; /* Remove default arrow in some browsers */
    -webkit-appearance: none; /* For Safari */
    -moz-appearance: none; /* For Firefox */
    cursor: pointer;
    background-image: url('data:image/svg+xml;utf8,<svg fill="white" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
    padding-right: 30px; /* Space for arrow */
}

.blocks-dropdown::-ms-expand {
    display: none; /* Hide default arrow in IE and Edge */
}

.add-feed-button-header {
    background-color: transparent;
    color: #A0A0A0;
    border: none;
    cursor: pointer;
    font-size: 1em;
    padding: 5px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.add-feed-button-header:hover {
    background-color: #3D3D3D;
    color: #fff;
}

.new-feed-input-container-header {
    display: flex;
    position: absolute;
    top: 100%; /* Position below the dropdown */
    right: 0;
    background-color: #171717;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.5);
    z-index: 101; /* Ensure it's above other content */
}

.new-feed-input-header {
    background-color: #3D3D3D;
    border: none;
    border-radius: 5px;
    padding: 8px;
    color: #fff;
    margin-right: 5px;
    flex-grow: 1;
}

.new-feed-input-container-header button.add-button-header { /* Added class name here */
    background-color: #F97316;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 0.9em;
    margin-left: 5px;
}

.new-feed-input-container-header button:last-child {
    background-color: #555;
}

.new-feed-input-container-header button:hover {
    background-color: #F59E0B;
}
.new-feed-input-container-header button:last-child:hover {
    background-color: #777;
}