/* Onboard.css */
.onboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
    background-color: #000;
    padding: 10px;
    font-family: 'Arial', sans-serif;
    color: #fff;
    overflow: hidden;
}

.onboard-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 700px;
    text-align: center;
    background-color: #3d3d3d;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.onboard-slide-dark {
    background-color: #222;
    color: #fff;
}

.onboard-logo {
    width: 80px;
    height: auto;
    margin-bottom: 15px;
}

.onboard-slide h2 {
    font-size: 1.6em;
    margin-bottom: 8px;
    color: #fff;
}

.onboard-slide-dark h2 {
    color: #fff;
}

.onboard-slide p {
    font-size: 0.95em;
    color: #A0A0A0;
    margin-bottom: 20px;
    line-height: 1.4;
}

.onboard-slide-dark p {
    color: #ddd;
}

.onboard-button {
    padding: 10px 25px;
    font-size: 0.9em;
    border: none;
    border-radius: 6px;
    background-color: #ff6600;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 5px;
}

.onboard-button-orange {
    background-color: #ff6600;
}

.onboard-button-orange:hover {
    background-color: #e65c00;
}

.onboard-button-last {
    background-color: #ff6600;
}

.onboard-button:hover {
    background-color: #e65c00;
}

.keyboard-shortcut, .keyboard-shortcut-inline {
    display: inline-flex;
    gap: 3px;
    margin: 15px 0;
}

.keyboard-shortcut span, .keyboard-shortcut-inline span {
    padding: 6px 10px;
    background-color: #3d3d3d;
    border-radius: 4px;
    font-weight: bold;
    font-size: 0.8em;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.1);
    color: #fff;
}

.onboard-slide-dark .keyboard-shortcut span, .onboard-slide-dark .keyboard-shortcut-inline span {
    background-color: #444;
    color: #fff;
    box-shadow: 1px 1px 2px rgba(255,255,255,0.1);
}


.onboarding-features {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
    margin-bottom: 20px;
}

.feature-item {
    padding: 10px;
    border: 1px solid #3d3d3d;
    border-radius: 6px;
    text-align: left;
    background-color: #3d3d3d;
}

.feature-item h3 {
    font-size: 1em;
    margin-bottom: 8px;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 5px;
}

.feature-item p {
    color: #A0A0A0;
    font-size: 0.85em;
    line-height: 1.3;
    margin-bottom: 0;
}

.onboard-steps-indicator {
    display: flex;
    gap: 6px;
    margin-top: 15px;
    margin-bottom: 10px;
}

.step-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #3d3d3d;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.step-dot.active {
    background-color: #ff6600;
}


/* Question mark and tooltip styling */
.question-mark {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: #ff6600;
    color: white;
    border-radius: 50%;
    font-size: 0.8em;
    text-align: center;
    line-height: 16px;
    cursor: pointer;
    margin-left: 5px;
    position: relative; /* For tooltip positioning */
}

.tooltip-text {
    visibility: hidden;
    width: 200px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position tooltip above the question mark */
    left: 50%;
    margin-left: -100px; /* Center tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

.question-mark:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

/* Suggestion Cards Styling */
.suggestion-cards-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
    width: 100%;
    max-width: 600px; /* Adjust as needed */
}

.suggestion-card {
    border-radius: 8px;
    padding: 15px 20px;
    color: white;
}

.suggestion-card-black {
    background-color: #1a1a1a; /* Darker background for black card */
}

.suggestion-card-orange {
    background-color: #ff6600; /* Orange background for orange card */
}

.suggestion-card-content p {
    font-size: 1em;
    line-height: 1.5;
    color: white; /* Ensure text is white or adjust as needed */
    margin: 0; /* Reset default paragraph margins */
}

/* Loading Spinner */
.loading-spinner {
    border: 4px solid #f3f3f3; /* Light grey border */
    border-top: 4px solid #ff6600; /* Orange border-top */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    margin: 20px auto; /* Center the spinner */
}

.orange-spinner {
    border-top-color: #ff6600;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.onboard-buttons-flex {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 10px;
}

.onboard-button-skip {
    background-color: #555;
    color: #fff;
}

.onboard-button-skip:hover {
    background-color: #666;
}

.book-demo-button {
    background: none;
    color: #ff6600;
    border: 1px solid #ff6600;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 0.8em;
    margin-left: 5px;
    transition: background-color 0.3s, color 0.3s;
}

.book-demo-button:hover {
    background-color: #ff6600;
    color: white;
}

/* Step 2 - New options styling */
.onboard-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 500px;
}

.onboard-option {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px;
    border: 1px solid #555;
    border-radius: 8px;
    background-color: #444;
    color: #fff;
    text-align: left;
    cursor: pointer;
    transition: border-color 0.3s, background-color 0.3s;
    font-size: 0.95em;
}

.onboard-option:hover, .onboard-option.selected {
    border-color: #ff6600;
    background-color: #555;
}

.onboard-option.selected {
    background-color: #ff660020; /* Lighter orange for selected state */
}


.option-icon {
    font-size: 1.2em; /* Adjust icon size as needed */
}

.continue-button {
    align-self: flex-end; /* Align to the right */
}

/* Step 3 - Grid Options Styling */
.onboard-options-grid {
    display: grid;
    grid-template-columns: 1fr; /* Single column grid */
    gap: 10px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 600px; /* Adjust as needed */
}

.onboard-option-grid {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Align text to left, selector to right */
    padding: 12px 15px; /* Reduced vertical padding */
    border: 1px solid #555;
    border-radius: 8px;
    background-color: #444;
    color: #fff;
    text-align: left;
    cursor: pointer;
    transition: border-color 0.3s, background-color 0.3s;
    font-size: 0.9em; /* Reduced font size */
}

.onboard-option-grid:hover, .onboard-option-grid.selected {
    border-color: #ff6600;
    background-color: #555;
}

.onboard-option-grid.selected {
    background-color: #ff660020; /* Lighter orange for selected state */
}

.option-icon-grid {
    display: flex;
    align-items: center;
    margin-right: 10px; /* Spacing between icon and text */
    font-size: 1em; /* Icon size in grid */
}

.option-selector {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid #777; /* Default border when not selected */
    background-color: transparent; /* Make background transparent */
    color: white; /* Icon color */
}

.onboard-option-grid.selected .option-selector {
    border-color: #ff6600; /* Orange border when selected */
    background-color: #ff6600; /* Orange background when selected */
    border: none;
}

.check-icon {
    font-size: 0.8em; /* Adjust check icon size */
}

.onboard-button-back {
    background-color: #555;
    color: #fff;
}

.onboard-button-back:hover {
    background-color: #666;
}