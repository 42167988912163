/* Api.css */
.api-page {
    background-color: #000;
    color: #fff;
    padding: 20px;
    font-family: 'Supreme', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 30px;
    max-width: 1200px;
    margin: 0 auto;
}

.api-page-title {
    font-size: 2em;
    margin-bottom: 20px;
    text-align: center;
}

.api-section-title {
    font-size: 1.5em;
    margin-bottom: 15px;
    border-bottom: 1px solid #333;
    padding-bottom: 5px;
}

.api-keys-section-grid,
.preferences-section-grid {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 20px;
    background-color: #171717;
    border-radius: 12px;
    padding: 20px;
    align-items: start;
}

.api-keys-list,
.memory-taste-section {
    flex: 1;
    min-width: 0;
}

.api-key-cards,
.preferences-cards {
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 200px;
}

.api-card {
    background-color: #3D3D3D;
    color: #fff;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.api-card:hover {
    background-color: #555;
}

.api-card-icon {
    font-size: 1.8em;
    color: #F97316;
}

.api-card-text {
    font-size: 1em;
}


.api-loading, .api-error, .api-no-data {
    color: #a0a0a0;
    text-align: center;
    margin-top: 10px;
}

/* API Keys Section Styles */
.api-key-item {
    margin-bottom: 10px;
}

.api-key-display {
    display: flex;
    align-items: center;
    background-color: #3D3D3D;
    border-radius: 8px;
    padding: 5px;
    overflow: hidden;
}

.api-key-input {
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 1em;
    padding: 10px;
    width: 100%;
    outline: none;
    font-family: 'monospace';
}

.api-key-show-button,
.api-key-copy-button {
    background-color: transparent;
    border: none;
    color: #a0a0a0;
    cursor: pointer;
    padding: 10px;
    border-radius: 0 8px 8px 0; /* Rounded on right for show button */
    transition: color 0.3s ease;
}

.api-key-copy-button {
    border-radius: 8px; /* Rounded on both sides for copy button */
}


.api-key-show-button:hover,
.api-key-copy-button:hover {
    color: #fff;
}


.api-generate-button {
    background-color: #F97316;
    color: black;
    border: none;
    padding: 10px 15px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1em;
    margin-top: 15px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.api-generate-button:hover {
    background-color: #cc7a29;
    color: white;
}

.api-new-key {
    margin-top: 10px;
    font-size: 0.9em;
    color: #a0a0a0;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.api-new-key span {
    color: #fff;
    font-family: 'monospace';
    display: block;
    margin-top: 5px;
}


/* History Table Styles */
.history-section {
    background-color: #171717;
    border-radius: 12px;
    padding: 20px;
    border-radius: 12px; /* Rounded section edges */
    overflow: hidden;     /* Clip inner content to rounded corners */
}

.history-table-container {
    overflow-x: auto;
}

.history-table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 12px 12px 0 0; /* Rounded top corners of table */
    overflow: hidden;             /* For top corner rounding to work */
}

.history-header-row {
    background-color: #3D3D3D;
}

.history-header-cell {
    padding: 10px 15px;
    text-align: left;
    border-bottom: 1px solid #555;
    font-weight: bold;
    color: #fff;
    white-space: nowrap;
}

.history-row:nth-child(even) {
    background-color: #222;
}

.history-cell {
    padding: 10px 15px;
    border-bottom: 1px solid #555;
    color: #a0a0a0;
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    text-align: center; /* Center align text in cells */
}
.history-cell svg { /* Style icons in table cells */
    display: block; /* Ensure icons behave like block elements for centering */
    margin: 0 auto; /* Center icons horizontally within cells */
}


/* Status Icons and Spinner */
.status-icon {
    font-size: 1.2em; /* Adjust icon size as needed */
}

.status-complete {
    color: #4CAF50; /* Green for complete */
}

.status-failed {
    color: #F44336; /* Red for failed */
}

.status-loading-spinner {
    display: inline-block;
    width: 1.2em;
    height: 1.2em;
    border: 3px solid rgba(249, 115, 22, 0.3); /* Orange with transparency */
    border-radius: 50%;
    border-top-color: #F97316; /* Solid orange for the top border */
    animation: spin 1s linear infinite;
    vertical-align: middle; /* Align spinner with text */
}

.status-loading-spinner-inner {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}


@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


/* History Pagination Styles */
.history-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    gap: 15px;
    color: #fff;
}

.history-pagination button {
    background-color: #3D3D3D;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.history-pagination button:hover:not(:disabled) {
    background-color: #555;
}

.history-pagination button:disabled {
    background-color: #2a2a2a;
    color: #777;
    cursor: default;
}
.history-total {
    color: #a0a0a0;
    font-size: 0.9em;
}


/* Memory, Taste, Retention Section Styles */
.memory-taste-section {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: #171717;
    border-radius: 12px;
    padding: 20px;
}

.preference-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.preference-label {
    color: #fff;
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    flex-grow: 1;
}

.preference-switch {
    flex-shrink: 0;
}


.preference-info-button {
    background: transparent;
    border: none;
    color: #a0a0a0;
    cursor: pointer;
    font-size: 0.8em;
    padding: 0;
    margin-left: 5px;
    transition: color 0.3s ease;
}

.preference-info-button:hover {
    color: #fff;
}

.preference-popover {
    position: absolute;
    top: -5px;
    left: 110%;
    background-color: #333;
    color: #fff;
    padding: 10px;
    border-radius: 8px;
    font-size: 0.8em;
    width: 200px;
    z-index: 10;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    white-space: normal;
}


/* Switch Styles (for Memory and Taste) - ADJUSTED FOR VISIBILITY */
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #555;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: #eee;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #F97316;
}

input:focus + .slider {
    box-shadow: 0 0 1px #F97316;
}

input:checked + .slider:before {
    transform: translateX(16px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}