/* Header.css */
.default-header {
  background-color: black;
  color: white;
  padding: 10px 10px;
  display: flex;

  justify-content: space-between;
  align-items: center;
}

.home-page-header {
  background-color: black ;

  color: white;
  padding: 15px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.room-share-header {
  background-color: #171717;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.header-left, .header-right-head {
  display: flex;
  align-items: center;
  color: white;
  font-size: 15px;
  font-weight: bolder;
}

.header-middle {
  display: flex;
  flex: 1; /* Take up available space */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}

.logo {
  color: white;
  text-decoration: none;
  font-size: 1.5em;
  margin-right: 10px;
}

/* Logo Image Style */
.logo-image {
  height: 50px; /* Adjust logo height as needed */
  margin-right: 10px;
}


nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

nav ul li {
  margin-left: 20px;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 0.9em;
}

.sign-in-button, .sign-up-button {
  background-color: #90D6FF;;
  color: black;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
  font-size: 0.9em;
  margin-left: 10px;
}

.sign-up-button {
  background-color: grey;
  color: black;
}


.user-section {
  position: relative;
  margin-left: 20px;
}

.user-button {
  background-color: transparent;
  color: white;
  border: none; /* Removed border as requested */
  padding: 8px 15px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.9em;
}

.user-dropdown-head {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #171717;
  border: 1px solid #555;
  border-radius: 8px;
  margin-top: 5px;
  width: 150px;
  z-index: 100;
}

.dropdown-item {
  padding: 10px 15px;
  cursor: pointer;
  font-size: 0.9em;
}

.dropdown-item:hover {
  background-color: #333;
}

.signout-dropdown-item button {
  background: none;
  color: white;
  border: none;
  padding: 0;
  font-size: 0.9em;
  cursor: pointer;
  text-align: left;
  width: 100%;
}

.signout-dropdown-item button:hover {
  color: #f44336;
}

.compute-section {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
}

.compute-icon {
  font-size: 1.2em;
  margin-right: 5px;
}

.compute-value {
  font-size: 0.9em;
}


/* Header Input Styles - Header.css */
.input-wrapper-header {
  width: 100%;
  max-width: 550px; /* Reduced max-width of input wrapper */
  position: relative;
  margin-left: 90px;
  word-wrap: break-word;
  z-index: 1500; /* Ensure above other header elements */

}


/* New Input Container Styles - based on provided example */
.InputContainer {
  height: 40px; /* Adjust height to match header */
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align items to start to accommodate input */
  background-color: #3D3D3D; /* Off-white light blue background */
  border-radius: 20px; /* Increased border-radius for more rounded corners */
  overflow: hidden;
  cursor: text; /* Change cursor to text for input */
  padding-left: 30px; /* Keep left padding */
  box-shadow: none; /* Remove box-shadow if not desired in header */
  border: 1px solid #3D3D3D; /* Match your existing input border */
  width: 80%; /* Take full width within input-wrapper-header */
  max-width: 100%; /* Take full width of parent */
}

.input-header { /* Style for input, renamed to be more specific */
  width: 100%; /* Take full width inside InputContainer */
  height: 100%; /* Match InputContainer height */
  border: none;
  outline: none;
  font-size: 1.1em; /* Increased font size */
  color: white; /* Darker text color for light background - changed to white */
  caret-color: #F97316; /* Orange caret color */
  background-color: transparent; /* Make input background transparent */
  font-family: 'Supreme', sans-serif; /* Keep your font family */
  padding: 0px 10px 0px 0px; /* Adjust padding */
  -webkit-appearance: none; /* Reset default styles */
  -moz-appearance: none;
  appearance: none; /* For other browsers */
}

.input-header::placeholder {
  color: #A0A0A0;
  opacity: 0.7;
  font-size: 15px;
}


.labelforsearch { /* Style for search icon label */
  cursor: pointer; /* Change cursor to pointer */
  padding: 0px 12px 0px 0px; /* Adjust padding */
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
}

.searchIcon { /* Style for search icon */
  width: 16px; /* Increased size */
  height: 16px;
  display: block; /* Make it a block element */

}

.searchIcon path { /* Style for search icon path */
  fill: white; /* Changed to white, will be overridden by light-blue-icon if class is present */
}

.light-blue-icon path {
    fill: #90D6FF; /* Light blue color for icons */
}


.border { /* You might not need this */
  height: 40%;
  width: 1.3px;
  background-color: rgb(223, 223, 223);
  margin-right: 10px; /* Add margin to separate from buttons */
}

.micIcon { /* Style for mic icon */
  width: 15px; /* Adjust size */
  height: 15px;
  display: block; /* Make it a block element */
}

.micIcon path { /* Style for mic icon path */
  fill:#90D6FF; /* Changed to white, will be overridden by light-blue-icon if class is present */
}

.micButton { /* Style for mic button */
  padding: 0px 10px; /* Adjusted padding */
  border: none;
  background-color: transparent;
  height: 40px;
  cursor: pointer;
  transition-duration: .3s;
  display: flex; /* Use flexbox */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
}

.header-button-style:hover {
    background-color: rgba(144, 214, 255, 0.2); /* Light blue hover */
    transition-duration: .3s;
}


.icon-button-header:hover,
.send-button-header:hover {
   background-color: rgba(144, 214, 255, 0.2); /* Light blue hover */
    transition-duration: .3s;
}


/* Input Buttons - Attachment and Send - Moved to be inline with InputContainer */
.input-buttons-header-inline {
  display: flex;
  align-items: center;
  margin-right: 5px; /* Added spacing to the right of the buttons */
}

.icon-button-header,
.send-button-header {
  background-color: transparent;
  border: none;
  color: white; /* Darker color for icons on light background */
  font-size: 14px;
  cursor: pointer;
  padding: 5px; /* Adjust padding for icon buttons */
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 1px;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-left: 5px; /* Spacing between buttons if needed */
}


.send-button-header {

  color: white; /* Dark text for send button on light background */
  padding: 5px 7px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
  margin-left: 10px; /* Added margin to separate send button */
}

.send-button-header:hover {
  background-image: #90D6FF;
  box-shadow: 0 0 10px #90D6FF;
  color: white; /* White text on hover for send button */
   background-color: rgba(144, 214, 255, 0.2); /* Light blue hover */
    transition-duration: .3s;
}

.send-button-icon-header {
  font-size: 14px;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-loader.send-button-icon-header {
  border: 2px solid rgba(0, 0, 0, 0.3); /* Darker border for loader on light background */
  border-top-color: #90D6FF;
  width: 12px;
  height: 12px;
}


/* Autocomplete Dropdown Styles for Header - No changes needed */
.autocomplete-dropdown-header {
  position: absolute;
  top: 100%;
  left: 0;
  /* right: 0;  changed to align with input */
  right: calc(50% - 275px); /* align right edge with input (assuming input max-width is 550px and centered) */
  left: calc(50% - 275px); /* align left edge with input */
  background-color: #171717;
  border: 1px solid #3D3D3D;
  border-top: none;
  border-radius: 0 0 12px 12px;
  overflow: hidden;
  z-index: 2000; /* Ensure it's on top */
}

.autocomplete-item-header {
  padding: 8px 15px; /* Slightly reduced padding */
  cursor: pointer;
  color: #fff;
  font-size: 14px; /* Match textarea font size */
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.autocomplete-item-header:hover {
  background-color: #3D3D3D;
}

/* Account Modal and Compute Modal Styles - No changes needed */
.account-modal-overlay, .signin-modal-overlay, .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000; /* Ensure it's on top */
}

.account-modal-content, .signin-modal-content, .modal-content, .upload-modal-content {
    background-color: #171717;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative; /* For close button positioning */
    color: white;
    max-width: 80%; /* Or a fixed width like 600px */
    max-height: 80%;
    overflow-y: auto; /* Enable scroll if content overflows */
    text-align: center;
    width: 80%;
    max-width: 500px;
}

.modal-close-button, .signin-modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    color: white;
    border: none;
    font-size: 1.2em;
    cursor: pointer;
}

.modal-title, .signin-modal-title {
    font-size: 1.5em;
    margin-bottom: 20px;
    color: #fff;
}

.modal-actions, .signin-modal-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-direction: column; /* Stack buttons vertically in signin modal */
}

.modal-confirm-button, .modal-cancel-button, .signin-modal-signin-button, .signin-modal-signup-button {
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    border: none;
    text-decoration: none;
    color: #fff;
    background-color: #3D3D3D;
    display: block;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
}

.modal-confirm-button {
    background-color: #4CAF50;
    color: #fff;
}

.modal-confirm-button:disabled {
    background-color: #555;
    cursor: not-allowed;
}


.modal-cancel-button {
    background-color: #3D3D3D;
    color: #fff;
}

.signin-modal-signup-button {
    background-color: #fff;
    color: #000;
}


.compute-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9); /* Darker overlay for compute modal */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000; /* Ensure it's on top */
}

.compute-modal-content {
    background-color: #171717;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative; /* For close button positioning */
    color: white;
    width: 90%;
    max-width: 1200px; /* Increased max width for modal */
    max-height: 90%;
    overflow-y: auto; /* Enable scroll if content overflows */
    display: flex; /* Flex container for sidebar and card display */
}

.compute-pricing-modal {
    flex-direction: row; /* Arrange sidebar and card horizontally */
    padding: 0; /* Remove padding from modal content */
    border-radius: 12px; /* Rounded corners for the modal */
    overflow: hidden; /* Clip content to rounded corners */
}

.compute-pricing-title {
    font-size: 1.8em;
    margin-bottom: 20px;
    text-align: center;
    padding: 20px 0;
    background-color: #222; /* Darker background for title section */
}

.compute-pricing-body {
    display: flex; /* Flex layout for sidebar and card area */
    flex: 1; /* Take remaining space */
}


.pricing-sidebar {
    width: 200px; /* Fixed width for sidebar */
    background-color: #222; /* Sidebar background color */
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: start; /* Align items to the start */
}

.pricing-sidebar-item {
    padding: 10px 20px;
    cursor: pointer;
    color: #ddd;
    transition: background-color 0.2s ease;
    border-left: 3px solid transparent; /* Indicator for selected item */
    display: flex; /* Use flex to align text and icon */
    justify-content: space-between; /* Space between text and icon */
    align-items: center; /* Center items vertically */
    width: 100%; /* Full width to make it clickable */
    text-align: left; /* Align text to the left */
}

.pricing-sidebar-item:hover, .pricing-sidebar-item.selected {
    background-color: #333;
    color: white;
    border-left-color: #90D6FF; /* Highlight color when selected or hovered */
}

.pricing-sidebar-item.selected {
    font-weight: bold; /* Make selected item text bold */
}

.selected-icon {
    color: #90D6FF; /* Color for the selected icon */
    margin-left: 5px; /* Space between text and icon */
}


.pricing-card-display {
    flex: 1; /* Take remaining space for card display */
    padding: 20px;
    background-color: #171717; /* Background for card display area */
    border-radius: 0 12px 12px 0; /* Rounded corners on the right side */
    overflow-y: auto; /* In case the card content is too long */
}


.compute-pricing-cards-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap; /* Allows cards to wrap on smaller screens */
    justify-content: center; /* Center cards horizontally */
    max-height: 70vh; /* Limit height and enable scrolling */
    overflow-y: auto;
    padding: 20px 0; /* Add some padding for scrollbar */
}

.single-card-view {
    width: 100%; /* Take full width of the display area */
    max-width: none; /* Remove max-width restriction */
    min-width: auto; /* Allow width to adjust */
    box-shadow: none; /* Remove box shadow as it's a single prominent card */
    transform: none !important; /* Disable hover transform */
}

.single-card-view:hover {
    box-shadow: none; /* Disable hover shadow */
}


.compute-pricing-card {
    background-color: #222; /* Standard dark grey background for all cards */
    border-radius: 12px;
    padding: 20px;
    width: 350px; /* Wider cards */
    min-width: 350px;
    height: 450px; /* Fixed height for all cards */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Distribute space evenly */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin: 0 auto; /* Center cards in the container */
}

.compute-pricing-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}


.card-title {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #fff;
    text-align: left; /* Align title to the left */
}

.card-description {
    font-size: 0.9em;
    color: #A0A0A0;
    margin-bottom: 20px;
    line-height: 1.4;
}

.card-price {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: left; /* Align price to the left */
    color: #fff;
}

.card-features-list {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
}

.card-feature-item {
    font-size: 0.9em;
    color: #fff;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
}

.feature-icon {
    margin-right: 8px;
    color: #90D6FF; /* Or a specific checkmark color */
}


.remaining-credits {
    margin-bottom: 20px;
    text-align: center;
}

.remaining-credits-label {
    font-size: 0.9em;
    color: #A0A0A0;
    margin-bottom: 5px;
}

.remaining-credits-value {
    font-size: 1.8em;
    color: #fff;
    font-weight: bold;
}

.balance-low {
    font-size: 0.8em;
    color: #f44336;
    margin-top: 5px;
}


.card-buttons {
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
    align-items: stretch; /* Stretch buttons to full width */
    gap: 10px; /* Spacing between buttons */
    margin-top: auto; /* Push buttons to the bottom of the card */
}

.choose-plan-button, .get-free-button {
    background-color: #333;
    color: white;
    border: none;
    padding: 10px 20px; /* Slightly larger buttons */
    border-radius: 8px;
    cursor: pointer;
    font-size: 0.9em;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
    width: 100%; /* Full width button */
    text-align: center;
}

.choose-plan-button:hover, .get-free-button:hover {
    background-color: #555;
}

.get-free-button {
    background-color: #90D6FF; /* Light blue for Get Free button */
    color: black; /* Dark text for contrast */
}

.get-free-button:hover {
    background-color: #b3e0ff; /* Lighter blue on hover */
}


/* Standard Plan Table Styles - Removed from Compute Modal */
.standard-plan-table-card {
    display: none; /* Hiding standard plan table */
}


/* Attached Files in Header Styles */
.attached-files-container-header {
    display: flex;
    gap: 5px;
    margin-top: 2px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    max-width: 600px;
}

.attached-file-item-header {
    background-color: #171717;
    border-radius: 8px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    color: #fff;
    font-size: 0.8em;
    width: 70px;
    text-align: center;
    position: relative;
}
.attached-file-item-header .attached-file-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    display: block;
}

.attached-file-item-header .attached-thumbnail-image {
    max-width: 60px;
    max-height: 40px;
    border-radius: 4px;
    display: block;
    object-fit: cover;
}

.attached-file-item-header .remove-attached-file {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: #F44336;
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 0.7em;
    padding: 0;
    line-height: 1;
}


/* Upload Modal Styles in Header */
.upload-modal-content { /* Reusing existing modal-content styles */

}

.upload-form { /* Reusing existing upload-form styles */
    display: flex;
    flex-direction: column;
    gap: 15px;
}


.upload-drop-area { /* Reusing existing upload-drop-area styles */
    border: 2px dashed #A0A0A0;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    color: #A0A0A0;
    transition: border-color 0.3s ease;
}

.upload-drop-area.dragging { /* Reusing existing upload-drop-area.dragging styles */
    border-color: #90D6FF;
    color: #fff;
}

.file-input { /* Reusing existing file-input styles */
    display: none;
}

.file-input-label { /* Reusing existing file-input-label styles */
    background-color: #90D6FF;
    color: #fff;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    display: inline-block;
    margin-top: 10px;
}

.selected-file-name { /* Reusing existing selected-file-name styles */
    margin-top: 10px;
    color: #fff;
    font-size: 0.9em;
}

.upload-error { /* Reusing existing upload-error styles */
    color: #ff4d4d;
    margin-top: 10px;
}

.upload-success { /* Reusing existing upload-success styles */
    color: lightgreen;
    margin-top: 10px;
}

.file-preview-container { /* Reusing existing file-preview-container styles */
    max-height: 200px;
    overflow: hidden;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #222;
}

.file-preview-image, .file-preview-video { /* Reusing existing file-preview-image, .file-preview-video styles */
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}