/* Neo.css */
.neo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 95vh;
    background-color: rgba(51, 51, 51, 0.8); /* Semi-transparent greyish #333333 */
    font-family: sans-serif;
}

.neo-card {
    background-color: rgba(51, 51, 51, 0.8); /* Semi-transparent greyish #333333 */
    border-radius: 15px;
    padding: 30px;
    width: 80%; /* Adjust width as needed, or use max-width for responsiveness */
    max-width: 500px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    color: white; /* Set text color to white for better visibility on grey background */
    position: relative; /* To position bottom icons relative to the card */
}

.neo-header {
    margin-bottom: 20px;
    text-align: left; /* Align header text to the left */
}

.neo-brand {
    color: #FF6700; /* Branded orange color */
    font-weight: bold;
    font-size: 1.8em; /* Increased brand font size "Neo" */
    display: block;
    margin-bottom: 5px;
}

.neo-title {
    font-size: 1.2em; /* Reduced title font size "How can I help..." */
    font-weight: bold;
    color: white; /* Ensure title text is white */
    line-height: 1.2;
    margin: 0;
}

.neo-bottom-icons {
    position: absolute; /* Position icons at the top right now */
    top: 15px; /* Changed from bottom to top */
    right: 15px;
    display: flex;
    gap: 10px;
}

.neo-bottom-icon {
    color: #ccc; /* Color for bottom icons */
    font-size: 1em; /* Size of bottom icons */
    cursor: pointer; /* Indicate they are interactive if needed */
}

.neo-notification-icon-container {
    position: relative; /* For positioning the border */
    border-radius: 50%; /* Make it circular */
    padding: 4px; /* Padding around the icon, adjust as needed */
}

.neo-notification-icon-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid red; /* Red border */
    border-radius: 50%; /* Circular border */
    opacity: 0; /* Initially hidden, set to 1 to always show or control with state */
}

/* If you want the border to always be visible, set opacity to 1 in .neo-notification-icon-container::before */
.neo-notification-icon-container.has-notifications::before {
    opacity: 1; /* Example class to show border when there are notifications */
}


/* From Uiverse.io by Cobp - Adjusted and Integrated */
.neo-chat-bot-container {
  display: flex;
  flex-direction: column;
  width: 100%; /* Take full width within neo-card */
  max-width: 100%; /* Ensure it doesn't exceed neo-card width */
}

.neo-chat-bot-container .neo-chat-options {
  position: relative;
  display: flex;
  background: linear-gradient(
    to bottom right,
    #7e7e7e,
    #363636,
    #363636,
    #363636,
    #363636
  );
  border-radius: 16px;
  padding: 1.5px;
  overflow: hidden;
  margin-bottom: 20px; /* Spacing below input area */
}

.neo-chat-bot-container .neo-chat-options::after {
    position: absolute;
    content: "";
    top: -10px;
    left: -10px;
    background: radial-gradient(
      ellipse at center,
      #ffffff,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.1),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0)
    );
    width: 30px;
    height: 30px;
    filter: blur(1px);
  }

.neo-chat-bot-container .neo-chat-options .neo-chat {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  width: 100%;
  overflow: hidden;
}

.neo-chat-bot-container .neo-chat .neo-chat-bot {
  position: relative;
  display: flex;
}

.neo-chat-bot-container .neo-chat .neo-chat-bot textarea {
  background-color: transparent;
  border-radius: 16px;
  border: none;
  width: 100%;
  height: 50px;
  color: #ffffff;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: 400;
  padding: 10px;
  resize: none;
  outline: none;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }

  &::placeholder {
    color: #f3f6fd;
    transition: all 0.3s ease;
  }
  &:focus::placeholder {
    color: #363636;
  }
}

.neo-chat-bot-container .neo-chat .neo-options {
  display: flex;
  justify-content: space-between; /* Align items to start and end */
  align-items: center; /* Vertically center items */
  padding: 10px;
}

.neo-chat-bot-container .neo-chat .neo-options .neo-btns-add {
  display: flex;
  gap: 8px;
}

.neo-chat-bot-container .neo-chat .neo-options .neo-btns-add button {
    display: flex;
    color: rgba(255, 255, 255, 0.1);
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    padding: 0; /* Remove default button padding */
    margin: 0; /* Remove default button margin */

    &:hover {
      transform: translateY(-5px);
      color: #ffffff;
    }
  }

.neo-chat-bot-container .neo-chat .neo-options .neo-btn-submit {
  display: flex;
  padding: 2px;
  background-image: linear-gradient(to top, #292929, #555555, #292929);
  border-radius: 10px;
  box-shadow: inset 0 6px 2px -4px rgba(255, 255, 255, 0.5);
  cursor: pointer;
  border: none;
  outline: none;
  transition: all 0.15s ease;
  margin-left: auto; /* Push submit button to the right */
  align-items: center; /* Center loader and icon vertically */
  justify-content: center; /* Center loader and icon horizontally */
}

.neo-chat-bot-container .neo-chat .neo-options .neo-btn-submit i {
    width: 30px;
    height: 30px;
    padding: 6px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    backdrop-filter: blur(3px);
    color: #8b8b8b;
    display: flex;        /* Enable flexbox for centering */
    align-items: center;   /* Vertically center content */
    justify-content: center; /* Horizontally center content */
}

.neo-chat-bot-container .neo-chat .neo-options .neo-btn-submit svg {
    transition: all 0.3s ease;
    width: 100%; /* Ensure SVG takes full width of container */
    height: 100%; /* Ensure SVG takes full height of container */
    display: block; /* Remove extra space below SVG */
}

.neo-chat-bot-container .neo-chat .neo-options .neo-btn-submit:hover svg {
  color: #f3f6fd;
  filter: drop-shadow(0 0 5px #ffffff);
}

.neo-chat-bot-container .neo-chat .neo-options .neo-btn-submit:focus svg {
  color: #f3f6fd;
  filter: drop-shadow(0 0 5px #ffffff);
  transform: scale(1.2) rotate(45deg) translateX(-2px) translateY(1px);
}

.neo-chat-bot-container .neo-chat .neo-options .neo-btn-submit:active {
  transform: scale(0.92);
}

.neo-tags {
  padding: 14px 0;
  display: flex;
  color: #ffffff;
  font-size: 10px;
  gap: 10px; /* Increased gap between tags */
  flex-wrap: wrap; /* Allow tags to wrap */
  justify-content: flex-start; /* Align tags to the start */
}

.neo-tags span {
    padding: 10px 16px; /* Increased padding for tags */
    background-color: #1b1b1b;
    border: 1.5px solid #363636;
    border-radius: 10px;
    cursor: pointer;
    user-select: none;
    display: inline-flex; /* Use inline-flex for icon alignment */
    align-items: center; /* Vertically align icon and text */
    gap: 8px; /* Increased spacing between icon and text */
    font-size: 1em; /* Slightly increased font size for tag text */
    position: relative; /* For status border overlay */
    overflow: visible; /* Ensure glow is visible outside the span */
}

.neo-tags span svg {
    font-size: 1.5em; /* Increased icon size */
    color: #ccc; /* Lighter color for suggestion icons */
}

.neo-tags span:hover::after {
    content: attr(title); /* Show name on hover */
    position: absolute;
    bottom: -30px; /* Adjusted to position tooltip further down */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 8px 12px; /* Adjusted padding for tooltip */
    border-radius: 5px;
    z-index: 10; /* Ensure tooltip is above other elements */
    white-space: nowrap; /* Prevent text from wrapping */
}

.neo-tags span.neo-task-generating {
    border-color: #FF6700; /* Orange border for generating */
    box-shadow: 0 0 0 0 rgba(255, 103, 0, 0.7); /* Initial shadow is small */
    animation: neo-task-glow 1.5s linear infinite; /* Apply glowing animation */
}

.neo-tags span.neo-task-generating::before {
    content: '';
    position: absolute;
    top: -3px; /* Adjusted for larger icons and padding */
    left: -3px;
    right: -3px;
    bottom: -3px;
    border: 3px solid #FF6700; /* Slightly thicker border */
    border-radius: 13px; /* Match span border-radius + border width */
}


.neo-tags span.neo-task-completed {
    border-color: #4CAF50; /* Green border for completed */
}
.neo-tags span.neo-task-completed::before {
    content: '';
    position: absolute;
    top: -3px; /* Adjusted for larger icons and padding */
    left: -3px;
    right: -3px;
    bottom: -3px;
    border: 3px solid #4CAF50; /* Slightly thicker border */
    border-radius: 13px; /* Match span border-radius + border width */
}


/* Custom CSS Loader */
.neo-loader {
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top: 3px solid #FF6700; /* Orange color */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: neo-spin 1s linear infinite;
}

@keyframes neo-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Glowing animation for task generating status */
@keyframes neo-task-glow {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 103, 0, 0.7);
  }
  50% {
    box-shadow: 0 0 10px 5px rgba(255, 103, 0, 0.7); /* Glow expands */
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 103, 0, 0.7); /* Glow retracts */
  }
}