/* src/components/LandingPage.css */
.landing-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    margin-top: 2em;
}

.hero-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.hero-text {
    flex: 1;
    padding-right: 20px;
    text-align: left;
}

.hero-text h1 {
    font-size: 3.5rem;
    margin-bottom: 20px;
    line-height: 1.2;
    font-weight: 700;
}

.hero-text p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 30px;
}

.hero-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.get-started-button {
    background-color: #0057FF;
    color: white;
    border: none;
    padding: 12px 20px;
    border-radius: 5px;
    font-size: 1rem;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.get-started-button:hover {
    background-color: #003eb5;
}

.request-demo-button {
    background-color: transparent;
    border: 1px solid #000;
    color: #000;
    padding: 12px 20px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.request-demo-button:hover {
    background-color: #000;
    color: #fff;
}

.trusted-by {
    text-align: left;
}

.trusted-by p {
    font-size: 14px;
    color: #777;
    margin-bottom: 5px;
}

.company-logos {
    display: flex;
    gap: 15px;
}

.company-logo {
    font-weight: 600;
    color: #333;
}

.hero-image {
    flex: 1;
    text-align: right;
}

.hero-image img {
    max-width: 100%;
    height: auto;
}

.features-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; /* Reduced gap */
    margin-bottom: 40px;
}

.feature-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    width: 100%;
}

.feature-card {
    background-color: #fff;
    padding: 25px;
    border: 1px solid #eee;
    border-radius: 8px;
    text-align: left;
    cursor: pointer;
    min-height: 150px;
    transition: filter 0.3s ease, background-color 0.3s ease;
}

.feature-card.inactive {
    filter: grayscale(100%);
}

.feature-card.active {
    background-color: #e0f2ff; /* Light blue background for active card */
}

.feature-card .card-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.feature-card .card-icon {
    font-size: 25px;
    margin-bottom: 10px;
}

.feature-card h3 {
    font-size: 1.3rem;
    margin-bottom: 10px;
}

.feature-card p {
    font-size: 1rem;
    color: #555;
    line-height: 1.4;
}

.active-card-image {
    width: 80%;
    max-width: 800px;
    margin-top: 20px; /* Added margin-top */
}

.active-card-image img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 8px;
}

.mockup-section {
    padding: 40px 20px;
    text-align: center;
    background-color: #f9f9f9;
    border-top: 1px solid #eee;
}

.mockup-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.mockup-section p {
    font-size: 1.1rem;
    color: #555;
    line-height: 1.6;
}