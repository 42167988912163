/* src/components/AccountPage.css */
.account-page-container {
    color: black; /* Set text color to black */
    display: flex;          /* Enable flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center;     /* Center vertically */
    height: 95vh;          /* Make container take full viewport height */
    /* Optional: Add background color or other styling for the container */
    /* background-color: #f0f0f0; */
}

/* Reduce the size of the Clerk UserProfile by 40% and keep it centered */
.cl-userProfile-root { /* Target the root element of UserProfile */
    transform: scale(0.6); /* Scale down to 60% of its original size */
    transform-origin: center; /* Scale from the center to maintain centering */
}

/* You can also target specific elements within the UserProfile if needed,
   though it's generally recommended to use Clerk's theming options if possible
   for consistent styling with other Clerk components.
   Example if you needed to target something specific inside UserProfile (inspect element in browser to find classes): */
/*
.cl-userProfile-root {  Example class, replace with actual class if needed
    color: black !important;  Use !important cautiously, prefer specificity
}
*/