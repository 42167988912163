/* Hub.css */
.hub-container {
    background-color: black;
    min-height: 98vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    padding: 20px;
    transition: padding 0.5s ease; /* Smooth transition for padding */
}

/* Gradient Title */
.hub-title {
    font-size: 3em;
    margin-bottom: 40px;
    background: linear-gradient(to right, #FF6700, #FFA500);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    transition: font-size 0.5s ease, margin-bottom 0.5s ease, margin-left 0.5s ease; /* Smooth transitions */
    align-self: center; /* Default center alignment */
}

.hub-title-small {
    font-size: 2em;
    margin-bottom: 20px;
    margin-left: 20px; /* Move title to the left */
    align-self: flex-start; /* Align to start when small */
}


/* Drag and Drop Box */
.drag-drop-box {
    border: 2px dashed white;
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    padding: 50px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 20px;
    transition: padding 0.5s ease, margin-bottom 0.5s ease, width 0.5s ease; /* Smooth transitions */
    width: 80%; /* Default width */
    max-width: 800px; /* Maximum width */
}

.drag-drop-box-active {
    background-color: rgba(255, 255, 255, 0.2);
}

.drag-drop-box-small {
    padding: 30px;
    margin-bottom: 10px;
    width: 60%; /* Reduced width when uploaded */
    max-width: 600px;
}


.hub-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    transition: margin-top 0.5s ease, margin-left 0.5s ease, gap 0.5s ease; /* Smooth transitions */
    margin-top: 0; /* Default margin top */
    margin-left: 0; /* Default margin left */
}

.hub-cards-small {
    margin-top: 20px; /* Push cards down when uploaded */
    margin-left: auto; /* Push cards to the right */
    gap: 10px; /* Reduced gap */
}

/* Gradient Cards with Black Text - Reduced Size */
.hub-card {
    background: linear-gradient(to bottom right, #FF6700, #FF8C00);
    border-radius: 10px;
    padding: 14px; /* Reduced padding */
    width: 140px; /* Reduced width (approx. 30%) */
    min-height: 126px; /* Reduced height (approx. 30%) */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: black;
    transition: transform 0.3s ease, box-shadow 0.3s ease, padding 0.5s ease, width 0.5s ease, min-height 0.5s ease; /* Smooth transitions */
}

.hub-card-small {
    padding: 10px;
    width: 100px;
    min-height: 90px;
}

.hub-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(255, 103, 0, 0.5);
}

.hub-card-icon {
    font-size: 2em; /* Reduced icon size */
    margin-bottom: 10px;
    color: black;
    transition: font-size 0.5s ease, margin-bottom 0.5s ease; /* Smooth transitions */
}

.hub-card-icon-small {
    font-size: 1.5em;
    margin-bottom: 5px;
}

.hub-card-title {
    font-size: 1em; /* Reduced title size */
    margin-bottom: 5px; /* Reduced margin */
    transition: font-size 0.5s ease, margin-bottom 0.5s ease; /* Smooth transitions */
}

.hub-card-title-small {
    font-size: 0.9em;
    margin-bottom: 3px;
}

.hub-card-description {
    text-align: center;
    font-size: 0.8em; /* Reduced description size */
    transition: font-size 0.5s ease; /* Smooth transitions */
}

.hub-card-description-small {
    font-size: 0.7em;
}


/* Uploaded Files Grid */
.uploaded-files-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr)); /* Responsive grid */
    gap: 15px;
    margin-top: 20px;
    width: 80%; /* Match drag and drop box width */
    max-width: 800px;
}

.uploaded-file-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border: 1px solid #444;
    border-radius: 5px;
}

.file-thumbnail {
    max-width: 80px;
    max-height: 80px;
    margin-bottom: 5px;
}

.file-thumbnail-icon {
    font-size: 3em;
    color: #ddd; /* Light grey icon color */
    margin-bottom: 5px;
}

.file-name {
    font-size: 0.8em;
    word-wrap: break-word;
    text-align: center;
    margin-bottom: 3px;
}

.file-type {
    font-size: 0.7em;
    color: #aaa;
}


/* Smart Source Switch Styles */
.smart-source-card {
    position: relative; /* For positioning the switch */
    padding-bottom: 40px; /* Add padding to accommodate switch and label */
}

.switch {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    width: 40px;
    height: 24px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #FF8C00; /* Orange color when ON */
}

input:focus + .slider {
    box-shadow: 0 0 1px #FF8C00;
}

input:checked + .slider:before {
    transform: translateX(16px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.switch-label {
    position: absolute;
    bottom: -5px; /* Position below the switch */
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.7em;
    color: #ddd;
}