/* Home.css */
.home-container-home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
    background-color:  black ;
    color: #fff;
    overflow-x: hidden;
    position: relative;
    min-height: 100vh; /* Extend page height */
}

/* New container style to push content below header */
.home-content-container {
    margin-top: 60px; /* Adjust this value based on your Header's height */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px; /* Added padding at the top of the content container */
}


.logo-container {
    margin-bottom: 10px;
}



.logo-container-signin {
    margin-bottom: 0px;
}

/* Reduce margin-bottom when signed in to minimize gap */
.home-container-home.signed-in .logo-container-signin {
    margin-bottom: 0px; /* Adjusted margin when signed in */
}

.home-container-home.signed-in .main-heading {
    min-height: 0; /* Remove min-height when signed in */
    margin-bottom: 0px; /* Also reduce margin-bottom of main heading when signed in */
}


.logo {
    width: 100px;
}

.logo-glow {
    animation: logo-pulse 2s infinite;
}

@keyframes logo-pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(144, 214, 255, 0.7);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(144, 214, 255, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(144, 214, 255, 0);
    }
}


.main-heading {
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 10px;
    min-height: 80px;
    color: white;
    letter-spacing: -1.5px;
    line-height: 2;
    overflow: hidden;
}

.sub-heading {
    font-size: 18px;
    max-width: 500px;
    margin-bottom: 40px;
    color: #A0A0A0;
}

.attached-files-container {
    display: flex;
    gap: 5px;
    margin-top: 2px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    max-width: 600px;
}

.attached-file-item {
    background-color: #171717;
    border-radius: 8px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    color: #fff;
    font-size: 0.8em;
    width: 70px;
    text-align: center;
    position: relative;
}

.attached-file-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    display: block;
}

.attached-thumbnail-image {
    max-width: 60px;
    max-height: 40px;
    border-radius: 4px;
    display: block;
    object-fit: cover;
}

.remove-attached-file {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: #F44336;
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 0.7em;
    padding: 0;
    line-height: 1;
}

.input-audio-container {
    display: flex;
    flex-direction: row; /* Revert back to row layout */
    width: 100%;
    margin-bottom: 10px;
    align-items: center; /* Vertically align logo and input */
    padding-left: 100px;
    padding-right: 0px;
    /* display: none; // Hide Input area in Home.js */
}

/* Reports Container Styles - Keep below input, full width, LEFT ALIGNED */
.reports-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start; /* LEFT ALIGNMENT - ensures buttons are left-aligned within *this* container */
    gap: 10px;
    width: 100%;
    max-width: 900px;
    padding-left: 100px; /* Align with logo start - KEEP THIS */
    padding-right: 0px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    align-items: flex-start; /* Align items at the top of the container if wrapping occurs */
    margin-left: 0; /* Ensure no extra margin is causing centering - NEW: Explicitly set margin-left to 0 */
    margin-right: auto; /* Allow right margin to auto-adjust if needed but keep left at 0 */
    z-index: 1; /* Autocomplete should be above buttons */
    margin-top: 20px; /* Added margin to separate from header content */
}


.audio-report-button-container,
.new-report-button {
    margin-bottom: 0;
}

/* New Report Button Style */
.report-button.new-report-button {
    background-color: #171717; /* Black background */
    color: #fff; /* White text */
    border: 1px solid #3D3D3D; /* Optional border for definition */
    padding: 8px 16px;
    border-radius: 18px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.2s ease, color 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    text-decoration: none;
    position: relative; /* For positioning spacer and switch */
}

.report-button.new-report-button:hover {
    background-color: #3D3D3D; /* Darker grey on hover */
    color: #fff;
}
.report-button.new-report-button.report-button-on {
    background-image: linear-gradient(to right, #4CAF50, #66BB6A);
}

.report-button.new-report-button.report-button-off {
    background-image: linear-gradient(to right, #F44336, #EF5350);
}

.report-button.light-blue-button {
    background-color: #90D6FF;
    color: black;
}

.report-button.light-blue-button:hover {
    background-color: #77c3ec;
}


.report-button.new-report-button.icon-only-button {
    padding: 8px;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none; /* Hide icon-only-button */
}
.report-button.new-report-button.icon-only-button svg {
    font-size: 16px;
}
.report-button.new-report-button.icon-only-button:hover {
    background-color: #3D3D3D; /* Darker grey on hover */
}
.report-button.new-report-button.icon-only-button:hover svg {
    color: #90D6FF; /* Highlight light blue on hover */
}


/* Live and 3D Report Button Specific Styles including Switches */
.live-report-button, .report-3d-button {
    padding-right: 30px; /* Space for icon and spacer */
    justify-content: flex-start; /* Align text to the left */
    position: relative; /* Ensure proper positioning context */
    display: none; /* Hide new-report-button */
    align-items: center;
}
.live-report-button.report-button-active, .report-3d-button.report-button-active {
    background-color: #4CAF50;
}

.report-button-spacer {
    display: inline-block;
    width: 5px; /* Spacing for icon */
}

.report-button-icon {
    margin-left: auto;
}


/* Input Wrapper Styles - Keep in Home.css */
.input-wrapper {
    width: 100%;
    max-width: 500px;
    margin-bottom: 20px; /* Reduced margin */
    position: relative;
    word-wrap: break-word; /* or overflow-wrap: break-word; */
    z-index: 1500;
    /* display: none; // Hide Input area in Home.js */
}

/* Earth Model Container Styles - Moved below features */

.earth-model-container {
    width: 100%;
    max-width: 200px;
    height: 150px;
    margin-bottom: 10px; /* Space below earth model before FAQ */
    /* display: none; // Hide Globe in Home.js */
}

.earth-model-container canvas {
    border-radius: 20px;
}



.text-area {
    width: 100%;
    min-width: 400px;
    width: 400px;
    min-height: 30px;
    height: auto;
    max-height: calc(120px * 3);
    background-color: #171717;
    border: 1px solid #3D3D3D;
    border-radius: 12px;
    padding: 20px;
    font-size: 16px;
    color: #fff;
    resize: vertical;

    margin-bottom: 0;
    font-family: 'Supreme', sans-serif;
    outline: none;
    padding-right: 70px;
    padding-left: 15px; /* Adjusted padding left */
    scrollbar-width: thin;
    scrollbar-color: #555 #171717;
    word-break: break-word;
    /* display: none; // Hide Input area in Home.js */
}

.text-area::-webkit-scrollbar {
    width: 6px;
}

.text-area::-webkit-scrollbar-track {
    background: #171717;
}

.text-area::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 3px;
}

.text-area::placeholder {
    color: #A0A0A0;
    opacity: 0.7;
}


.input-buttons {
    position: absolute;
    bottom: 5px;
    left: 15px;
    right: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* display: none; // Hide Input area in Home.js */
}

.left-buttons, .right-buttons {
    display: flex;
    align-items: center;
}

.icon-button,
.public-button,
.send-button,
.rooms-button,
.upload-button {
    background-color: transparent;
    border: none;
    color: #A0A0A0;
    font-size: 14px;
    cursor: pointer;
    padding: 8px 8px;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 1px;
    transition: background-color 0.3s ease, color 0.3s ease;
    /* display: none; // Hide Input area in Home.js */
}

.icon-button:hover,
.public-button:hover,
.rooms-button:hover,
.upload-button:hover {
    background-color: #3D3D3D;
    color: #fff;
}

.rooms-button.rooms-button-active {
    background-image: linear-gradient(to right, #90D6FF, #a8ddf7);
    color: #fff;
}


.icon-button svg, .public-button svg, .rooms-button svg, .upload-button svg {
    font-size: 16px;
}

.button-text {
    margin-left: 0px;
}

.public-button-container {
    position: relative;
    margin-right: 10px;
    z-index: 20;
}

.public-button {
    display: flex;
    align-items: center;
    background-color: #1E293B;
    color: #fff;
    padding: 10px 15px;
}

.public-icon,
.dropdown-icon,
.private-icon,
.privacy-option-icon {
    margin-right: 5px;
    font-size: 16px;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #171717;
    border: 1px solid #3D3D3D;
    border-radius: 8px;
    z-index: 25;
    width: 180px;
    margin-top: 5px;
    margin-right: -10px;
}

.dropdown-item {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    cursor: pointer;
    color: #fff;
    justify-content: space-between;
}

.dropdown-item:hover {
    background-color: #3D3D3D;
}

.private-icon {
    margin-right: 5px;
}

.starter-text {
    color: #A0A0A0;
    font-size: 12px;
    margin-left: auto;
}

.send-button {
    position: relative;
    bottom: auto;
    right: auto;
    margin-left: 10px;
    background-color: #505050;
    color: #fff;
    padding: 7px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease;
}

.send-button:hover {
    background-image: linear-gradient(to right, #90D6FF, #a8ddf7);
    box-shadow: 0 0 10px #90D6FF;
}

.send-button-icon {
    font-size: 16px;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-loader {
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #90D6FF;
    width: 14px;
    height: 14px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.signin-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
}

.signin-modal-content {
    background-color: #171717;
    border-radius: 12px;
    padding: 20px;
    width: 80%;
    max-width: 400px;
    position: relative;
    text-align: center;
}

.signin-modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 1.2em;
    cursor: pointer;
}

.signin-modal-title {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #fff;
}

.signin-modal-subtitle {
    color: #A0A0A0;
    margin-bottom: 20px;
}

.signin-modal-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.signin-modal-signin-button,
.signin-modal-signup-button {
    padding: 10px 20px;
    border-radius: 8px;
    text-decoration: none;
    color: #fff;
    background-color: #3D3D3D;
    cursor: pointer;
    display: block;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
}

.signin-modal-signup-button {
    background-color: #fff;
    color: #000;
}


.card-actions {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    opacity: 0;
    transition: opacity 0.3s ease;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    flex-wrap: wrap;
    padding: 10px;
    z-index: 1;
    box-sizing: border-box;
}

.image-grid-item:hover .card-actions, .rooms-grid-item:hover .card-actions.rooms-actions {
    opacity: 1;
}

.card-actions.rooms-actions {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}


.card-action-icon {
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    border: none;
    padding: 8px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    text-decoration: none;
    width: 36px;
    height: 36px;
    margin-bottom: 5px;
}

.card-actions .card-action-icon svg {
    display: block;
}

.card-action-icon:hover {
    background-color: rgba(0, 0, 0, 0.9);
}


.card-actions {
    max-width: 200px;
}


.search-row {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    gap: 10px;
}
.search-input-container {
    align-items: center;
    background-color: #171717;
    border-radius: 8px;
    padding: 8px 15px;
    width: 100%;
    max-width: 400px;
    display: flex;
}

.search-icon {
    color: #A0A0A0;
    margin-right: 10px;
    font-size: 16px;
}

.search-input {
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 16px;
    width: 100%;
    outline: none;
}

.upload-button {
    background-color: #1E293B;
    color: #fff;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-button:hover {
    background-color: #3D3D3D;
}


.load-more-container {
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

.load-more-button {
    background-color: #1E293B;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.load-more-button:hover {
    background-color: #3D3D3D;
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.modal-content, .async-modal-content, .upload-modal-content, .privacy-modal-content, .async-confirmation-modal-content, .privacy-modal-content, .asset-settings-modal-content {
    background-color: #171717;
    border-radius: 12px;
    padding: 20px;
    width: 80%;
    max-width: 500px;
    position: relative;
    text-align: center;
}


.modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 1.2em;
    cursor: pointer;
}

.modal-title {
    font-size: 1.5em;
    margin-bottom: 20px;
    color: #fff;
}

.modal-actions {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.modal-confirm-button, .modal-cancel-button, .modal-remove-button, .modal-share-button {
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    border: none;
}

.modal-confirm-button {
    background-color: #4CAF50;
    color: #fff;
}

.modal-confirm-button:disabled {
    background-color: #555;
    cursor: not-allowed;
}


.modal-cancel-button, .modal-remove-button, .modal-share-button {
    background-color: #f44336;
    color: #fff;
}

.modal-cancel-button.modal-cancel-button, .modal-remove-button.modal-cancel-button, .modal-share-button.modal-cancel-button {
    background-color: #3D3D3D;
    color: #fff;
}


.upload-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.upload-form .input-row {
    display: flex;
    gap: 15px;
    margin-bottom: 10px;
}

.upload-form .input-row .upload-input,
.upload-form .input-row .upload-select {
    width: 50%;
}

.upload-input, .upload-select {
    background-color: #3D3D3D;
    border: none;
    border-radius: 8px;
    padding: 10px 15px;
    color: #fff;
    font-size: 16px;
    outline: none;
    font-family: 'Supreme', sans-serif;
}

.upload-select {
    appearance: none;
    -webkit-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
    padding-right: 30px;
}

.upload-drop-area {
    border: 2px dashed #A0A0A0;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    color: #A0A0A0;
    transition: border-color 0.3s ease;
}

.upload-drop-area.dragging {
    border-color: #90D6FF;
    color: #fff;
}

.file-input {
    display: none;
}

.file-input-label {
    background-color: #90D6FF;
    color: #fff;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    display: inline-block;
    margin-top: 10px;
}

.selected-file-name {
    margin-top: 10px;
    color: #fff;
    font-size: 0.9em;
}

.upload-error {
    color: #ff4d4d;
    margin-top: 10px;
}

.upload-success {
    color: lightgreen;
    margin-top: 10px;
}

.file-preview-container {
    max-height: 200px;
    overflow: hidden;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #222;
}

.file-preview-image, .file-preview-video {
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}


.no-results-card {
    background-color: #3d3d3d;
    border-radius: 12px;
    padding: 30px;
    text-align: center;
    width: 40%;
    margin: 0 auto;
    display: block;
}
.no-results-title {
    font-size: 1.3em;
    color: #fff;
    margin-bottom: 10px;
}

.no-results-description {
    color: #A0A0A0;
}


.asset-settings-modal-content {
    max-width: 600px;
}

.asset-preview {
    margin-bottom: 20px;
    border-radius: 8px;
    overflow: hidden;
    max-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #222;
}

.settings-asset-media {
    display: block;
    max-width: 100%;
    max-height: 200px;
    height: auto;
}
.settings-asset-media.audio-settings-media {
    max-height: 100px;
}

/* Audio Reports Section Styles */
.audio-reports-container {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between play button and report button if needed, now not needed */
}

.audio-report-button-container {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between play button and report button if needed, now not needed */
}

.report-button {
    background-color: #90D6FF;
    color: black;
    border: none;
    padding: 8px 16px;
    border-radius: 18px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.2s ease;
    display: flex; /* To center icon and text */
    align-items: center; /* To center icon and text vertically */
    justify-content: center; /* To center icon and text horizontally */
    gap: 5px; /* Space between icon and text */
    text-decoration: none;
}

.report-button:hover {
    background-color: #77c3ec; /* Darker light blue on hover */
}

.audio-preview-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.settings-audio-element {
    width: 100%;
}


.settings-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.settings-input-group {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.settings-label {
    text-align: left;
    margin-bottom: 5px;
    color: #fff;
    font-size: 0.9em;
}

.settings-input, .settings-select {
    margin-bottom: 10px;
}

.asset-settings-modal-content .modal-actions {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
}

.asset-settings-modal-content .modal-cancel-button {
    margin-left: auto;
}

.asset-settings-modal-content .modal-share-button {
    margin-left: 10px;
}

.copied-message {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    z-index: 101;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.copied-message.visible {
    opacity: 1;
}

.clickable-card {
    cursor: pointer;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.clickable-card:hover {
    transform: scale(1.03);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.video-icon-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.welcome-asset-item:hover .video-icon-overlay,
.welcome-asset-item:hover .audio-icon-overlay,
.welcome-asset-item:hover .download-icon-overlay {
    opacity: 1;
}

.video-play-button, .audio-icon-overlay .video-play-button {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
    transform: scale(0.7);
}



.video-overlay-icon, .audio-overlay-icon {
    font-size: 2em;
    color: #90D6FF;
    width: 15px;
    height: 15px;
}

.video-modal-overlay, .audio-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
}

.video-modal-content, .audio-modal-content {
    background-color: #171717;
    border-radius: 12px;
    padding: 20px;
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
    position: relative;
}

.video-modal-close-button, .audio-modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 1.2em;
    cursor: pointer;
}

.video-player, .audio-player {
    width: 100%;
    height: auto;
    border-radius: 8px;
    display: block;
}

.media-container video.grid-media {
    object-fit: cover;
    height: 100%;
}

.upload-button svg {
    font-size: 24px;
}


.rooms-grid-item.generating .generating-image-overlay,
.rooms-grid-item.completed .generating-image-overlay {
    border-radius: 12px;
    overflow: hidden;
}

.room-card-name {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px;
    text-align: center;
    font-size: 0.9em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}


.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-spinner {
    border: 4px solid #f3f3f3;
    border-top: #90D6FF;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}


.copied-message {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    z-index: 101;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.copied-message.visible {
    opacity: 1;
}

.expanded-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.expanded-modal-content {
    background-color: black;
    padding: 20px;
    border-radius: 12px;
    max-width: 90vw;
    max-height: 90vh;
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.expanded-modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #555;
}

.expanded-modal-title {
    margin-top: 0;
    margin-bottom: 10px;
    text-align: center;
}


.expanded-asset-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
}

.expanded-asset-media {
    max-width: 100%;
    max-height: 60vh;
    border-radius: 8px;
    display: block;
    object-fit: contain;
}

.expanded-asset-media.pdf-viewer {
    width: 100%;
    height: 70vh;
}

.expanded-asset-thumbnail {
    max-width: 80%;
    max-height: 50vh;
    border-radius: 8px;
    margin-bottom: 10px;
    display: block;
    object-fit: contain;
}


.expanded-asset-name {
    font-size: 1em;
    color: #333;
    text-align: center;
    margin-bottom: 15px;
}


.expanded-modal-actions {
    display: flex;
    justify-content: center;
    width: 100%;
}

.expanded-download-button {
    background-color: #90D6FF;
    color: black;
    border: none;
    padding: 8px 12px;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    text-decoration: none;
    font-size: 0.9em;
}

.expanded-download-button:hover {
    background-color: #77c3ec;
}

.download-text {
    font-size: 0.9em;
}


.expand-icon-overlay {
    position: absolute;
    top: 5px;
    right: 5px;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.download-icon-overlay {
    position: absolute;
    top: 5px;
    left: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
    border-top-left-radius: 8px;
    opacity: 0;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    transition: opacity 0.3s ease;
}

.welcome-asset-media-container:hover .expand-icon-overlay {
    opacity: 1;
}

.expand-overlay-icon {
    font-size: 14px;
}


.welcome-asset-item .welcome-asset-media-container {
    transition: transform 0.3s ease;
}

.welcome-asset-item:hover .welcome-asset-media-container {
    transform: scale(1.05);
}


.welcome-map-container {
    margin-top: 20px;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #3D3D3D;
    background-color: #171717;
}

.map-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    color: white;
    background-color: #222;
}

.expand-map-button {
    background-color: #90D6FF;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.9em;
}

.welcome-map-iframe {
    width: 100%;
    height: 400px;
    border: none;
}

.expanded-map-iframe {
    width: 100%;
    height: 80vh;
    border: none;
}


.typing-indicator {
    display: inline-block;
}

.ellipsis {
    display: inline-block;
    animation: ellipsis-anim 1.5s infinite;
}

@keyframes ellipsis-anim {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}


.welcome-assets-loader-container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 20px 0;
}

.welcome-assets-page-loader {
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #90D6FF;
    width: 30px;
    height: 30px;
    animation: spin 1.5s linear infinite;
}

.expanded-asset-media.powerpoint-viewer {
    width: 100%;
    height: 80vh;
}

.welcome-iframe-container {
    margin-top: 20px;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #3D3D3D;
    background-color: #171717;
}

.iframe-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    color: white;
    background-color: #222;
}

.expand-iframe-button {
    background-color: #90D6FF;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.9em;
}

.welcome-iframe {
    width: 100%;
    height: 500px;
    border: none;
}

.expanded-asset-media.expanded-iframe {
    width: 100%;
    height: 85vh;
    border: none;
}

.expanded-asset-media.powerpoint-viewer {
    width: 100%;
    height: 90vh;
}

.settings-asset-media.powerpoint-viewer {
    width: 100%;
    height: 50vh;
}

.home-embed-section {
    width: 90%;
    margin-bottom: 20px;
    border-radius: 15px;
    overflow: hidden;
    border: 1px solid #3D3D3D;
    background-color: #171717;
    display: flex;
    justify-content: center;
}

.home-embed-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.home-embed-container iframe {
    width: 100%;
    height: 70vh; /* Adjusted height to 70vh */
    border: none;
    display: block;
}

/* Embed Content Wrapper and Exit Button */
.embed-content-wrapper {
    position: relative; /* To position exit button */
    width: 100%;
    height: 400px; /* Match iframe height */
}

.embed-content-wrapper iframe {
    width: 100%;
    height: 100%; /* Iframe fills wrapper */
    border: none;
    display: block;
}