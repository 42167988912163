/* Game.css */
/* General body styles */
body {
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevent scrollbars */
  font-family: 'Arial', sans-serif; /* Set a default font */
  color: white;
}

/* Main container for the application */
.main-container {
  width: 100vw;
  height: 98vh;
  position: relative; /* To position the canvas and overlay */
  background-color: black; /* Background color for the main container (same as body) */
}

/* Babylon.js canvas styles */
.babylon-canvas {
  width: 100%;
  height: 100%;
  outline: none;
}

/* Overlay styles */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 10;
  pointer-events: none;
  align-items: center; /* Center items horizontally */
  /* justify-content: center;  Removed vertical centering to position elements from top */
}

/* Top Left Branding Container */
.top-left-branding {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  pointer-events: auto; /* Make clickable */
}

/* Back Arrow Style */
.back-arrow {
  cursor: pointer;
  margin-right: 10px;
  border: 2px solid grey;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(61, 61, 61, 0.6); /* Consistent grey background */
}

/* Brand Text Style */
.brand-text {
  color: #FF6700; /* Brand Orange */
  font-weight: bold;
  font-size: 1em; /* Adjust size as needed */
  border: 2px solid grey;
  padding: 5px 10px;
  border-radius: 20px;
  background-color: rgba(61, 61, 61, 0.6); /* Consistent grey background */
}

/* Search input container styles */
.search-input-container {
  display: flex;
  justify-content: center;
  margin-top: 30px; /* Adjusted margin-top */
  width: 100%;
  pointer-events: auto;
  position: absolute; /* Position search input at the top */
  top: 30px;
  left: 0;
}

/* Search input styles */
.search-input {
  padding: 8px 15px;
  border: 3px solid #FF6700; /* Orange border */
  border-radius: 20px;
  width: 400px;
  background-color: rgba(0, 0, 0, 0.8); /* More black background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  color: #fff;
  outline: none;
  pointer-events: auto;
  height: auto; /* Adjust height automatically */
  overflow-y: auto; /* Enable vertical scroll if content exceeds max-height */
  resize: vertical; /* Allow vertical resizing if needed */
  min-height: 40px; /* Minimum height to avoid collapsing */
  line-height: 1.4em; /* Adjust line height for multi-line text */
  display: block; /* Ensure block display for height: auto to work correctly */
  max-height: 5em; /* Limit to ~4 lines (line-height * 4 + padding + border) */
  font-family: 'Arial', sans-serif; /* Inherit font-family */
  white-space: pre-wrap; /* Handle line breaks */
  word-wrap: break-word; /* Break long words */
}

/* Hidden search input styles */
.search-input.hidden {
  display: none;
}

/* Embed container styles */
.embed-container {
  width: 80%; /* Adjust width as needed */
  max-width: 800px; /* Maximum width of the embed container */
  margin-top: 20px; /* Space between search input and embed */
  pointer-events: auto; /* Make iframe and controls interactive */
  display: flex;
  flex-direction: row; /* Changed to row to place controls to the right */
  align-items: flex-start; /* Align items to the start (top) */
  border: 3px solid #FF6700; /* Orange border around the embed container */
  border-radius: 10px;
  overflow: hidden; /* Clip content to rounded corners */
}

/* Embed iframe styles */
.embed-iframe {
  border: none; /* Remove iframe border */
  /* Orange border is now on the embed-container */
  border-radius: 0; /* Remove border radius from iframe itself */
  overflow: hidden; /* Ensure content within iframe is clipped to bounds */
  width: 100%; /* Iframe takes up full width of embed-container */
  display: block; /* Ensure iframe is treated as a block element */
}

/* Embed controls styles */
.embed-controls {
  display: flex;
  flex-direction: column; /* Icons stacked vertically on the right */
  justify-content: flex-start; /* Align icons to the top of the control area */
  align-items: center; /* Center icons horizontally in the control area */
  padding: 10px; /* Add some padding around the icons */
  background-color: rgba(0, 0, 0, 0.5); /* Optional: Background for the icon area */
}

/* Embed control button styles */
.embed-control-button {
  background-color: rgba(61, 61, 61, 0.6);
  border: 2px solid white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 5px; /* Space between buttons vertically */
}

/* Embed control button: last child to remove bottom margin from the last button */
.embed-control-button:last-child {
  margin-bottom: 0;
}


/* Navigation arrows container styles */
.nav-arrows-container {
  position: absolute;
  bottom: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;
}

/* Navigation arrow button styles */
.nav-arrow-button {
  margin: 0 10px;
  cursor: pointer;
  background-color: rgba(61, 61, 61, 0.6); /* Adjusted background color */
  border: 2px solid #3d3d3d; /* Adjusted border color */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
}

/* Scene title container styles */
.scene-title-container {
  position: absolute;
  bottom: 30px;
  width: 100%;
  text-align: center;
  color: white;
  font-size: 18px;
  font-weight: bold;
  pointer-events: none;
}

/* Canvas container styles */
.canvas-container {
  position: relative;
  width: 100%;
  height: 100%;
}

/* Navigation button container styles */
.nav-button-container {
  position: absolute;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
}

/* Navigation button circle styles */
.nav-button-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #3d3d3d; /* Adjusted border color */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: #3d3d3d; /* Adjusted background color */
}

/* Navigation button icon styles */
.nav-button-icon {
  /* Icon styling */
}

/* Loading card styles */
.loading-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #333333; /* Adjusted background color to #333333 */
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  z-index: 20;
  display: flex; /* Enable flexbox */
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
}

/* Loading card text styles */
.loading-card p {
  color: white;
  margin-top: 10px;
}

/* Loading spinner styles */
.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #FF6700; /* Adjusted loader color to #FF6700 - ORANGE */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
  margin-bottom: 10px; /* Add some space between spinner and text */
}

/* Spin animation keyframes */
@keyframes spin {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}

/* Close scene button styles */
.close-scene-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  background-color: rgba(61, 61, 61, 0.6); /* Adjusted background color */
  border: 2px solid white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
  z-index: 30;
}

/* Block icon button styles */
.block-icon-button {
  position: absolute;
  bottom: 70px;
  right: 20px;
  cursor: pointer;
  background-color: rgba(61, 61, 61, 0.6); /* Adjusted background color */
  border: 2px solid white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
  z-index: 30;
}

/* Modal overlay styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(61, 61, 61, 0.75); /* Adjusted background color */
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Modal content styles */
.modal-content {

  color: white;
  border-radius: 10px;
  padding: 20px;
  width: 500px;
  max-width: 90%;
  position: relative;
  pointer-events: auto;
  background-color: #333333; /* Added background color for modal */
}

/* Modal header styles */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Modal header title styles */
.modal-header h2 {
  margin: 0;
}

/* Modal close button styles */
.modal-close-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: white;
}

/* Modal body styles */
.modal-body {
  /* Add styles for the content area */
}

/* Modal scene item styles */
.modal-scene-item {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  color: white;
}

/* Modal scene item hover effect */
.modal-scene-item:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Highlight on hover */
}

/* Initial cards container */
.initial-cards-container {
  display: flex;
  flex-direction: column; /* Vertical layout */
  gap: 20px; /* Spacing between cards */
  align-items: center; /* Center cards horizontally */
  margin-top: 100px; /* Push initial cards down below search input */
}

/* Initial card style */
.initial-card {
  background-color: #333333;
  color: white;
  padding: 20px 30px;
  border-radius: 15px;
  cursor: pointer;
  font-size: 1.2em;
  text-align: left; /* Align text to the left */
  transition: background-color 0.3s ease;
  width: 400px; /* Set a fixed width for cards */
  position: relative; /* For arrow positioning */
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Space description and arrow */
  align-items: center; /* Vertically align description and arrow */
  pointer-events: auto;
}

.initial-card:hover {
  background-color: #555555;
}

/* Initial card description style */
.initial-card-description {
  font-size: 0.9em; /* Adjusted font size to 0.9em - REDUCED FONT SIZE */
  color: #ddd; /* Slightly lighter text for description */
  margin-right: 20px; /* Add some spacing between description and arrow */
}

/* Initial card arrow style */
.initial-card-arrow {
  position: absolute; /* Position arrow at the bottom right */
  right: 15px;
  bottom: 15px;
}

/* Orange card style for "Renders" Initial Card */
.initial-card.orange-card {
    background-color: #FF6700; /* Orange Background */
    color: white; /* White text for contrast */
    border: 2px solid #FF6700; /* Optional: Orange border if needed */
}

.initial-card.orange-card:hover {
    background-color: #FF7A1A; /* Slightly lighter orange on hover for feedback */
}