/* src/components/Settings.css */
.settings-container {
    display: flex;
    height: 100vh;
  }
  
  .main-content {
    flex-grow: 1;
    padding: 20px;
    margin-left: 250px; /* Offset for fixed sidebar */
  }