/* Usage.css */

.pricing-page {
  background-color: black;
  min-height: 94vh;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: sans-serif;
  color: white;
  max-height: 200px; /* Added max height */
  overflow-y: auto; /* Added internal scroll */
  margin-top: 1rem; /* Added margin top */
}

.pricing-page::-webkit-scrollbar {
  width: 8px; /* Adjust scrollbar width as needed */
}

.pricing-page::-webkit-scrollbar-thumb {
  background-color: #666; /* Scrollbar thumb color */
  border-radius: 4px; /* Scrollbar thumb rounded corners */
}

.pricing-page::-webkit-scrollbar-track {
  background-color: #222; /* Scrollbar track color */
  border-radius: 4px; /* Scrollbar track rounded corners */
}


.billing-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
  width: 100%;
  max-width: 720px;
}

.credit-button-container {
  /* No changes needed here unless you want to further adjust width */
}

.credit-button {
  background-color: #222;
  color: white;
  border: none;
  padding: 6px 9px;
  font-size: 0.6em;
  border-radius: 4.8px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
  margin-right: auto;
}

.credit-button:hover {
  background-color: #444;
}

.plus-icon {
  margin-left: 3px;
  font-size: 0.48em;
}

.billing-toggle-container {
    display: flex;
    justify-content: center;
    flex-grow: 1;
}


.billing-toggle {
  display: flex;
  background-color: #222;
  border-radius: 4.8px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}

.billing-toggle button {
  background: none;
  color: white;
  border: none;
  padding: 6px 12px;
  font-size: 0.6em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.billing-toggle button.active {
  background-color: #FF6700;
  color: black;
}

.billing-toggle button:hover:not(.active) {
  background-color: #444;
}


.pricing-cards-container {
  display: flex;
  gap: 12px;
  width: 100%;
  max-width: 720px;
  justify-content: center;
  margin-bottom: 18px;
}

.pricing-card {
  background-color: #333333;
  border-radius: 6px;
  padding: 15px;
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  justify-content: space-between;
}

.pricing-card.featured-card {
    background: linear-gradient(to bottom, #333333, #444444);
}

.badge {
  position: absolute;
  top: -6px;
  left: 12px;
  background-color: #FF6700;
  color: black;
  padding: 3px 6px;
  border-radius: 3px;
  font-size: 0.48em;
  font-weight: bold;
}


.plan-name {
  font-size: 0.9em;
  margin-bottom: 9px;
  color: white;
}

.price {
  font-size: 1.2em;
  font-weight: bold;
  color: #FF6700;
  margin-bottom: 6px;
  display: flex;
  align-items: flex-start;
}

.price-suffix {
  font-size: 0.42em;
  font-weight: normal;
  color: white;
  margin-left: 3px;
  align-self: flex-end;
  margin-bottom: 3px;
}

.features-separator {
  width: 100%;
  height: 0.6px;
  background-color: #555;
  margin-bottom: 9px;
}

.features-list {
  list-style: none;
  padding: 0;
  margin-bottom: 12px;
  width: 100%;
}

.features-list li {
  display: flex;
  align-items: center;
  margin-bottom: 4.8px;
  font-size: 0.54em;
  color: #ddd;
}

.checkmark {
  width: 12px;
  height: 12px;
  margin-right: 6px;
  color: #FF6700;
}

.subscribe-button {
  background-color: #444;
  color: white;
  border: none;
  padding: 7.2px 15px;
  border-radius: 4.8px;
  font-size: 0.6em;
  cursor: pointer;
  width: 100%;
  text-align: center;
  transition: background-color 0.3s ease;
  margin-top: auto;
}

.subscribe-button:hover {
  background-color: #FF6700;
  color: black;
}

.subscribe-button.active-button {
  background-color: #FF6700;
  color: black;
  cursor: default;
}

.subscribe-button.active-button:hover {
    background-color: #FF6700;
    color: black;
    cursor: default;
}


/* Small Cards Styling */
.small-cards-container {
  display: flex;
  gap: 12px;
  width: 100%;
  max-width: 720px;
  justify-content: center;
  flex-wrap: wrap;
}

.small-card {
  background-color: #333333;
  border-radius: 6px;
  width: 48%;
  min-width: 180px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.small-card.expanded {
    overflow: visible;
}

.small-card-header {
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.plan-title-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


.small-card-title {
  font-size: 0.72em;
  color: white;
  margin-bottom: 3px;
}

.small-price {
  font-size: 1.08em;
  color: #FF6700;
  margin-bottom: 0;
}

.small-suffix {
  font-size: 0.42em;
}

.discount-note {
    font-size: 0.48em;
    color: #ddd;
}


.details-button {
  background: none;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.6em;
}

.chevron-icon {
  width: 12px;
  height: 12px;
  margin-left: 3px;
  transition: transform 0.3s ease;
}

.chevron-down {
  transform: rotate(0deg);
}

.chevron-up {
  transform: rotate(180deg);
}


.small-card-action {
  padding: 0 12px 12px 12px;
  display: flex;
  justify-content: flex-start;
}


.request-access-button, .invite-friends-button {
  background-color: #444;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4.8px;
  font-size: 0.6em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.request-access-button:hover, .invite-friends-button:hover {
  background-color: #FF6700;
  color: black;
}


.question-icon {
  margin-left: 3px;
  font-size: 0.54em;
}


.small-card-details {
  padding: 0 12px 12px 12px;
}


/* Responsive adjustments if needed */
@media (max-width: 540px) {
  .pricing-cards-container {
    flex-direction: column;
    align-items: center;
  }
  .pricing-card {
    width: 90%;
    max-width: 240px;
  }

  .small-cards-container {
    flex-direction: column;
    align-items: center;
  }
  .small-card {
    width: 90%;
    max-width: 300px;
    min-width: auto;
  }

  .billing-options {
    flex-direction: column;
    align-items: center;
  }

  .credit-button-container, .billing-toggle-container {
    width: 90%;
    max-width: 240px;
    margin-bottom: 6px;
  }
}