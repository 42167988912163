/* Share.css */
.share-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh; /* At least 80% of viewport height */
    padding: 20px;
    background-color: #000;
    color: #fff;
    flex-direction: column; /* Align items vertically in container */
}

.share-image, .share-video, .share-glb-video {
    max-width: 40%; /* For images and videos */
    max-height: 80vh;
    display: block;
    margin: 0 auto; /* Center horizontally */
}

.share-video, .share-glb-video {
    width: auto; /* Adjust width for video to maintain aspect ratio */
}


.audio-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%; /* Match image width */
    max-width: 400px; /* Or a fixed max width */
}

.audio-thumbnail-overlay {
    position: relative;
    cursor: pointer;
}

.share-audio-thumbnail {
    width: 100%;
    display: block;
    border-radius: 8px;
}

.play-button-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.play-button-circle {
    background-color: black;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid black; /* Black border */
}

.play-icon {
    font-size: 2em;
    color: #F97316; /* Orange play icon */
}

.share-audio-player {
    width: 100%;
    margin-top: 10px;
}


.share-embed {
    width: 80%; /* Take up 80% of the container width */
    height: 600px; /* Adjust height as needed */
    max-width: 80%;
    display: block;
    margin: 20px auto; /* Center horizontally and add margin */
    border: none;
}

.no-assets-card {
    background-color: #3d3d3d;
    border-radius: 12px;
    padding: 30px;
    text-align: center;
    width: 80%; /* Take up 80% of the container width */
    max-width: 600px; /* Maximum width for the card */
}

.no-assets-title {
    font-size: 1.3em;
    color: #fff;
    margin-bottom: 10px;
}

.no-assets-description {
    color: #A0A0A0;
}