.marker-card {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  pointer-events: auto;
  /* Initially hidden, will show when coordinates are available */
    /* Added transition for smooth appearance */
  transition: opacity 0.3s ease-in-out;
}

/* Rest of the styles remain the same */
.marker-card-content {
  min-width: 150px;
  font-size: 0.8em;
}

.marker-card h3 {
  margin-top: 0;
  font-size: 1em;
}

.marker-card p {
  margin: 5px 0;
}