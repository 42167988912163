/* visitorContent.css */

.visitor-content {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: auto;
    height: auto;
}

/* Audio Reports Section Styles */
.visitor-content .audio-reports-container {
    display: flex;
    justify-content: center; /* Center the audio reports container */
    gap: 20px; /* Space between report types */
    margin-bottom: 20px; /* Space below audio reports and above quick actions */
    width: 100%; /* Take full width to center effectively */
}

.visitor-content .audio-report-button-container {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between play button and report button if needed, now not needed */
}

.visitor-content .report-button {
    background-color: #90D6FF;
    color: black; /* Changed to black */
    border: none;
    padding: 8px 16px;
    border-radius: 18px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.2s ease;
    display: flex; /* To center icon and text */
    align-items: center; /* To center icon and text vertically */
    justify-content: center; /* To center icon and text horizontally */
    gap: 5px; /* Space between icon and text */
}

.visitor-content .report-button:hover {
    background-color: #7bc0e8; /* Darker blue on hover */
}


/* Quick Actions (now Segment Buttons) Styles */
.visitor-content .quick-actions {
    display: flex;
    gap: 8px;
    justify-content: center;
    margin-bottom: 30px;
    flex-wrap: wrap; /* Allow buttons to wrap on smaller screens */
}

.visitor-content .quick-action-button {
    background-color: transparent;
    border: 1px solid #3D3D3D;
    color: #A0A0A0;
    font-size: 12px;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 18px;
    transition: background-color 0.2s ease, color 0.2s ease, border-color 0.2s ease; /* Added border-color transition */
    position: relative;
    overflow: hidden;
    margin-bottom: 5px; /* Add a bit of margin at the bottom for better spacing when wrapping */
}

.visitor-content .quick-action-button:hover,
.visitor-content .quick-action-button.active {
    background-color: #90D6FF;
    color: black; /* Changed to black */
    border-color: #90D6FF; /* Keep border blue when active/hovered */
}


/* Embed Section Styles */
.visitor-content .embed-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 30px;
    padding: 0 20px;
}

.visitor-content .embed-container {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    border: none; /* Or border: 0; */
    margin-bottom: 30px; /* Space between iframe and features section */
    display: flex; /* Add this line */
    justify-content: center; /* Add this line */
    position: relative; /* Make embed container relative for overlay positioning */
}

/* Embed Overlay Card Styles */
.visitor-content .embed-overlay-card {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7); /* Black with transparency */
    color: white;
    padding: 10px 15px;
    border-radius: 8px;
    font-size: 12px;
    z-index: 10; /* Ensure it's on top of the iframe */
    max-width: 150px; /* Adjust as needed */
    line-height: 1.4;
}

.visitor-content .embed-overlay-card.bottom-right {
    bottom: 10px;
    right: 10px;
}

.visitor-content .embed-overlay-card.top-right {
    top: 10px;
    right: 10px;
}

.visitor-content .embed-overlay-card.top-left {
    top: 10px;
    left: 10px;
}


.visitor-content .embed-overlay-card.crime-card {
    /* Styles specific to crime card if needed */
}

.visitor-content .embed-overlay-card.economy-card {
    bottom: 50px; /* Shift economy card up if both are present */
    right: 10px;
}

.visitor-content .embed-overlay-card.ai-generated-card {
    /* Styles specific to AI Generated card if needed */
}

.visitor-content .embed-overlay-card.imagine-space-card {
    /* Styles specific to Imagine Any Space card if needed */
}

/* 3D Container Styles */
.visitor-content .three-d-container {
    display: flex;
    width: 100%;
    height: 400px;
    border-radius: 10px;
    overflow: hidden;
}

.visitor-content .three-d-video-container {
    width: 50%; /* Take half the container width */
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000; /* Black background for video */
}

.visitor-content .three-d-video {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* Keep video aspect ratio */
}

.visitor-content .digital-physical-card {
    width: 50%; /* Take half the container width */
    height: 100%;
    background-color: #171717; /* Dark grey background */
    color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.visitor-content .digital-physical-title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 10px;
}

.visitor-content .digital-physical-description {
    font-size: 0.9em;
    line-height: 1.4;
    color: #A0A0A0;
}

/* Feature Section Styles */
.visitor-content .feature-section, .visitor-content .faq-section, .visitor-content .era-section {
    margin-bottom: 30px;
}

.visitor-content .feature-section {
    width: 100%;
    max-width: 1200px;
    padding: 30px 20px;
    text-align: center;
    margin-bottom: 30px; /* Space between features and earth model */
}

.visitor-content .feature-section-heading, .visitor-content .faq-section-heading, .visitor-content .era-section-heading {
    font-size: 28px;
    font-weight: 700;
    color: white;
    margin-bottom: 25px;
    line-height: 1.2;
    overflow: hidden;
}

.visitor-content .features-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.visitor-content .feature-item {
    background-color: #171717;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    max-width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.visitor-content .feature-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.visitor-content .feature-icon {
    font-size: 2.2em;
    color: #90D6FF;
    margin-bottom: 15px;
}

.visitor-content .feature-title {
    font-size: 1.3em;
    font-weight: 600;
    color: white;
    margin-bottom: 8px;
    text-align: center;
}

.visitor-content .feature-description {
    color: #A0A0A0;
    font-size: 0.9em;
    line-height: 1.4;
    text-align: left;
}

/* Earth Model Container Styles - Moved below features */
.visitor-content .earth-model-container {
    width: 100%;
    max-width: 400px;
    height: 300px;
    margin-bottom: 30px; /* Space below earth model before FAQ */
}

.visitor-content .earth-model-container canvas {
    border-radius: 20px;
}


/* FAQ Section Styles */
.visitor-content .faq-section {
    width: 70%;
    max-width: 1200px;
    padding: 30px 20px;
    text-align: left;
    margin-bottom: 30px; /* Space below FAQ before ERA */
    padding-left: 20px; /* ADDED PADDING */
    padding-right: 20px; /* ADDED PADDING */
}

.visitor-content .faq-items {
    border-radius: 10px;
    overflow: hidden;
}

.visitor-content .faq-item {
    background-color: #171717;
    border-bottom: 1px solid #3D3D3D;
    padding: 15px 20px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    align-items: flex-start;
}

.visitor-content .faq-item:last-child {
    border-bottom: none;
}

.visitor-content .faq-item:hover {
    background-color: #3D3D3D;
}

.visitor-content .faq-question {
    font-size: 0.95em;
    color: white;
    font-weight: 500;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.visitor-content .faq-arrow {
    color: #A0A0A0;
    font-size: 1em;
}

.visitor-content .faq-answer {
    color: #A0A0A0;
    padding-top: 10px;
    font-size: 0.9em;
    line-height: 1.4;
    display: none;
}

.visitor-content .faq-item:hover .faq-answer {
    display: block;
}


/* Era Section Styles */
.visitor-content .era-section {
    width: 70%;
    max-width: 1200px;
    padding: 30px 20px;
    text-align: center;
    margin-bottom: 30px; /* Space below ERA section */
    padding-left: 20px; /* ADDED PADDING */
    padding-right: 20px; /* ADDED PADDING */
}


.visitor-content .era-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 15px;
    justify-content: center;
}

.visitor-content .era-item {
    background-color: #171717;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.visitor-content .era-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}


.visitor-content .era-title {
    font-size: 1.2em;
    font-weight: 600;
    color: white;
    margin-bottom: 8px;
    text-align: center;
}

.visitor-content .era-description {
    color: #A0A0A0;
    font-size: 0.9em;
    line-height: 1.4;
    text-align: left;
}