/* SignInModal.css */
.signin-modal-overlay-signinmodal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
}

.signin-modal-content-signinmodal {
    background-color: #171717;
    border-radius: 12px;
    padding: 20px;
    width: 80%;
    max-width: 400px;
    position: relative;
    text-align: center;
}

.signin-modal-close-button-signinmodal {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 1.2em;
    cursor: pointer;
}

.signin-modal-title-signinmodal {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #fff;
}

.signin-modal-subtitle-signinmodal {
    color: #A0A0A0;
    margin-bottom: 20px;
}

.signin-modal-buttons-signinmodal {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.signin-modal-signin-button-signinmodal,
.signin-modal-signup-button-signinmodal {
    padding: 10px 20px;
    border-radius: 8px;
    text-decoration: none;
    color: #fff;
    background-color: #3D3D3D;
    cursor: pointer;
    display: block;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
}

.signin-modal-signup-button-signinmodal {
    background-color: #fff;
    color: #000;
}